import React, {useEffect, useRef, useState} from 'react'

import {Loader} from 'layout/Loader'
import css from './FormIframe.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const FormIframe: React.FC<{
	formassembly_id: number
	params?: string
	isSubmitted?: () => void
}> = ({formassembly_id, params, isSubmitted}) => {
	const [isLoaded, setIsLoaded] = useState(false)
	const iframeRef = useRef<any>(null)

	const syncHeight = () => {
		if (
			!iframeRef.current ||
			!iframeRef.current?.contentWindow ||
			!iframeRef.current?.contentWindow?.document?.documentElement?.scrollHeight
		)
			return
		iframeRef.current.style.height =
			iframeRef.current.contentWindow.document.documentElement.scrollHeight +
			'px'
	}
	const onLoad = () => {
		setIsLoaded(true)
		syncHeight()
	}
	const receiveMessage = (message) => {
		if (message?.data !== 'Finished') return
		if (message?.source === iframeRef.current?.contentWindow && isSubmitted) {
			isSubmitted()
			return // Skip message in this event listener
		}
	}

	useEffect(() => {
		const intervalID = setInterval(syncHeight, 500)
		window.addEventListener('message', receiveMessage)
		return () => {
			clearInterval(intervalID)
			window.removeEventListener('message', receiveMessage)
		}
	})

	return (
		<>
			{!isLoaded && <Loader />}
			<iframe
				className={styles.formiframe()}
				src={`/api/iframes/${formassembly_id}?${params}`}
				ref={iframeRef}
				frameBorder="0"
				scrolling="no"
				onLoad={onLoad}
			/>
		</>
	)
}
