import React from 'react'
import { DPRoadmapNode } from 'types/DPTypes'
import { Page } from './Page'

export const Roadmap: React.FC<DPRoadmapNode> = (page) => {
  return <Page
    parent={page.program}
    tags={[]}
    color={null}
    {...page}
  />
}
