import {DPImage, DPUrl} from 'types/DPTypes'
import React, {Fragment} from 'react'

import {Image} from 'layout/Image'
import {Link} from 'util/link'
import {Mod} from 'types/basictypes'
import NextImage from 'next/image'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Card.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Card: React.FC<{className?: string; url?: string}> = ({
	className,
	url,
	children
}) => {
	const LinkTag = url ? Link : 'div'
	const linkProps = url ? {to: url} : {}

	return (
		<LinkTag {...linkProps} className={styles.card.with(className)()}>
			{children}
		</LinkTag>
	)
}

export const CardImage: React.FC<{
	className?: string
	image: DPImage
	url?: DPUrl
}> = ({className, image, url}) => {
	const LinkTag = url ? Link : 'div'
	const linkProps = url ? {to: url} : {}

	return (
		<LinkTag {...linkProps} className={styles.image.with(className)()}>
			<div className={styles.image.wrapper()}>
				{image?.src && (
					<>
						<Image {...image} format="landscape_thumbnail" />
						<span className={styles.image.overlay()} />
					</>
				)}
			</div>
		</LinkTag>
	)
}

export const CardLogo: React.FC<{
	className?: string
	image: DPImage
	url?: DPUrl
}> = ({className, image, url}) => {
	const LinkTag = url ? Link : 'div'
	const linkProps = url ? {to: url} : {}

	if (!image?.src) return null

	return (
		<LinkTag {...linkProps} className={styles.logo.with(className)()}>
			<NextImage
				src={image.src}
				alt={image?.alt}
				layout="fill"
				objectFit="contain"
			/>
		</LinkTag>
	)
}

export const CardTitle: React.FC<{
	className?: string
	as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
	title: string
	url?: string
}> = ({className, as, title, url}) => {
	if (!title) return null
	const LinkTag = url ? Link : Fragment
	const linkProps = url ? {to: url, className: styles.title.link()} : {}

	return (
		<Title.H5 as={as} className={styles.title.with(className)()}>
			<LinkTag {...linkProps}>
				<Wysiwyg>{title}</Wysiwyg>
			</LinkTag>
		</Title.H5>
	)
}

export const CardText: React.FC<{
	className?: string
	mod?: Mod<'large'>
	text: string
}> = ({className, mod, text}) => {
	if (!text) return null
	return (
		<Wysiwyg className={styles.text.with(className).mod(mod)()}>{text}</Wysiwyg>
	)
}
