import {HierarchicalMenu, RefinementList} from 'react-instantsearch-hooks-web'
import {
	Overview,
	OverviewBottom,
	OverviewContent,
	OverviewFilters,
	OverviewResults,
	OverviewSearchLabel,
	OverviewTab,
	OverviewTop
} from 'layout/overview/Overview'

import {Autocomplete} from 'util/autocomplete'
import {Block} from 'blocks/Block'
import {Container} from 'layout/Container'
import {DPFullprojectsBlock} from 'types/DPTypes'
import React from 'react'
import {Title} from 'layout/Title'
import css from './FullProjects.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useRouter} from 'next/dist/client/router'
import {useTranslation} from 'locale/translate'

const styles = fromModule(css)

export const FullProjects: React.FC<
	DPFullprojectsBlock & {
		_initial: {
			ongoing: any
			finished: any
			time: string
		}
	}
> = ({title, _initial}) => {
	const t = useTranslation()
	const router = useRouter()
	const {slug, tab} = router.query
	const activeType =
		tab === 'finished'
			? '/nl/projectstatus/finished'
			: '/nl/projectstatus/ongoing'

	return (
		<Block type="fullprojects" title={title} className={styles.fullprojects()}>
			<Container>
				{title && (
					<Title.Marked
						as="h1"
						className={styles.fullprojects.title()}
						mod="large"
					>
						{title}
					</Title.Marked>
				)}
				<Overview
					types={[activeType]}
					initial={tab === 'finished' ? _initial?.finished : _initial?.ongoing}
				>
					<OverviewTop>
						<OverviewTab
							isActive={activeType === '/nl/projectstatus/ongoing'}
							tab={undefined}
						>
							{t.projects.tab_running}
						</OverviewTab>
						<OverviewTab
							isActive={activeType === '/nl/projectstatus/finished'}
							tab={'finished'}
						>
							{t.projects.tab_finished}
						</OverviewTab>
					</OverviewTop>
					<OverviewContent>
						<OverviewFilters>
							<Autocomplete
								placeholder={t.overview.searchbox.placeholder}
								detachedMediaQuery="none"
								openOnFocus
							/>
							<OverviewSearchLabel>{t.projects.categories}</OverviewSearchLabel>
							<HierarchicalMenu
								attributes={[
									'project_categories.lvl0',
									'project_categories.lvl1'
								]}
							/>
							<OverviewSearchLabel>{t.projects.region}</OverviewSearchLabel>
							<RefinementList attribute="project_region" limit={30} />
							<OverviewSearchLabel>{t.projects.domains}</OverviewSearchLabel>
							<RefinementList attribute="project_domains" limit={30} />
							<OverviewSearchLabel>
								{t.overview.filters.tags}
							</OverviewSearchLabel>
							<RefinementList attribute="tags" limit={30} />
						</OverviewFilters>
						<OverviewResults />
					</OverviewContent>
					<OverviewBottom />
				</Overview>
			</Container>
		</Block>
	)
}
