import { CardText, CardTitle } from 'layout/cards/Card'
import React from 'react'
import { DPNode } from 'types/DPTypes'
import { fromModule } from 'util/styler/Styler'
import css from './NodeRow.module.scss'

const styles = fromModule(css)

export const NodeRow: React.FC<{
	node: DPNode
}> = ({node}) => {
	return (
		<div className={styles.noderow()}>
			<div className={styles.noderow.content()}>
				<CardTitle as="h3" title={node.title} url={node.url} />
				{'description' in node && <CardText
					text={node.description}
					className={styles.noderow.content.text()}
					mod="large"
				/>}
			</div>
		</div>
	)
}
