import {DPImage, DPWysiwyg} from 'types/DPTypes'

import {Image} from './Image'
import React from 'react'
import {Wysiwyg} from './Wysiwyg'
import css from './Intro.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export type IntroType = {
	image?: DPImage
	description?: DPWysiwyg
}

export const Intro: React.FC<IntroType & {reverse?: boolean}> = ({
	image,
	description,
	reverse
}) => {
	if (!image && !description) return null

	return (
		<div className={styles.intro.mod({reverse: reverse && description})()}>
			{image?.src && (
				<Image
					{...image}
					priority
					className={styles.intro.image()}
					layout={image.width < 1200 ? 'intrinsic' : 'responsive'}
					sizes="(max-width: 1200px) 100vw, 1200px"
				/>
			)}
			{description && (
				<Wysiwyg className={styles.intro.description()}>{description}</Wysiwyg>
			)}
		</div>
	)
}
