import {Block} from 'blocks/Block'
import {Container} from 'layout/Container'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {useTranslation} from 'locale/translate'
import React, {useContext} from 'react'
import {DPProduct, DPShopBlock} from 'types/DPTypes'
import {GeneralContext} from 'types/general'
import {fromModule} from 'util/styler/Styler'
import {BasketContextProvider, useBasket} from '../basket/BasketContext'
import css from './Shop.module.scss'
import {ShopRow} from './ShopRow'

const styles = fromModule(css)

export const Shop: React.FC<DPShopBlock> = ({title, products}) => {
	return (
		<Block type="shop" title={title} className={styles.shop()}>
			<BasketContextProvider products={products}>
				<Container>
					{title && (
						<Title.Marked as="h1" className={styles.shop.title()} mod="large">
							{title}
						</Title.Marked>
					)}
					<ShopNotifications />
					<div className={styles.shop.items()}>
						{products.map((product) => (
							<ShopRow
								key={product.node_id}
								product={product}
								show_cart={true}
							/>
						))}
					</div>
				</Container>
			</BasketContextProvider>
		</Block>
	)
}

const ShopNotifications: React.FC = () => {
	const {notification} = useBasket()

	return (
		<div id="shop_notifications">
			{notification && <ShopNotification product={notification?.product?.ui} />}
		</div>
	)
}

export const ShopNotification: React.FC<{product: DPProduct}> = ({product}) => {
	const t = useTranslation()
	const {links} = useContext(GeneralContext)
	if (!product) return null

	return (
		<div className={styles.notification()} id="notifications">
			<div className={styles.notification.row()}>
				<div className={styles.notification.row.content()}>
					<p>
						<b>{product.title}</b> {t.shop.is_added}
					</p>
				</div>
				{links.basket && (
					<div className={styles.notification.row.link()}>
						<LinkStyled to={links.basket}>{t.shop.show_basket}</LinkStyled>
					</div>
				)}
			</div>
		</div>
	)
}
