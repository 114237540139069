import {Block} from 'blocks/Block'
import {Wysiwyg} from 'layout/Wysiwyg'
import React from 'react'
import {DPTextBlock} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import css from './Text.module.scss'

const styles = fromModule(css)

export const Text: React.FC<DPTextBlock> = ({id, text, has_background}) => {
	if (!text) return null

	return (
		<Block
			type="text"
			id={'text' + id}
			className={styles.text.mod({background: has_background})()}
		>
			<Wysiwyg>{text}</Wysiwyg>
		</Block>
	)
}
