import {Block} from 'blocks/Block'
import {CardImage} from 'layout/cards/Card'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import React from 'react'
import {DPOverviewblockBlock, DPOverviewblockItemBlock} from 'types/DPTypes'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import css from './Overviewblock.module.scss'

const styles = fromModule(css)

export const Overviewblock: React.FC<DPOverviewblockBlock> = ({
	title,
	text,
	items
}) => {
	if (!items || !items.length) return null

	return (
		<Block
			type="overviewblock"
			title={title}
			className={styles.overviewblock()}
		>
			{title && (
				<Title.Marked className={styles.overviewblock.title()}>
					{title}
				</Title.Marked>
			)}
			{text && (
				<Wysiwyg className={styles.overviewblock.text()}>{text}</Wysiwyg>
			)}
			<div className={styles.overviewblock.items()}>
				{items.map((item, i) => (
					<div key={i} className={styles.overviewblock.items.item()}>
						<OverviewItem {...item} />
					</div>
				))}
			</div>
		</Block>
	)
}

const OverviewItem: React.FC<DPOverviewblockItemBlock> = ({
	image,
	title,
	link
}) => {
	return (
		<div className={styles.item()}>
			<CardImage image={image} url={link.url} />
			<h5 className={styles.item.title()}>
				<Link to={link.url}>{title}</Link>
			</h5>
		</div>
	)
}
