import {Button} from 'layout/Button'
import {CardText, CardTitle} from 'layout/cards/Card'
import {EventcardBranding, EventcardDate} from 'layout/cards/Eventcard'
import React from 'react'
import {DPEvent} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import css from './EventRow.module.scss'

const styles = fromModule(css)

export const EventRow: React.FC<{
	event: DPEvent
}> = ({event}) => {
	return (
		<div className={styles.eventrow()}>
			<div className={styles.eventrow.content()}>
				<EventcardBranding branding={event.branding} />
				<div className={styles.eventrow.date()}>
					<EventcardDate {...event} />
				</div>
				<div className={styles.eventrow.info()}>
					<div className={styles.eventrow.info.content()}>
						<CardTitle as="h4" title={event.title} url={event.url} />
						<CardText
							text={event.description}
							className={styles.eventrow.info.content.text()}
							mod="large"
						/>
					</div>
					{event.subscribe_link && (
						<div className={styles.eventrow.info.button()}>
							<Button to={event.subscribe_link.url}>
								{event.subscribe_link.title}
							</Button>
							{event.price?.name && (
								<p className={styles.eventrow.info.button.price()}>
									{event.price?.label || event.price.name}
								</p>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
