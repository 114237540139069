import {
	BasketContextProvider,
	useBasket
} from 'blocks/fullwidth/basket/BasketContext'
import {Content, ContentBlocks, ContentSidebar} from 'layout/content/Content'
import {DPProduct, DPProductNode} from 'types/DPTypes'
import {Hero, HeroBack, HeroTitle} from 'layout/Hero'
import {Paragraphs, splitContent} from 'blocks/Block'

import {Blocks} from 'layout/content/Blocks'
import {Button} from 'layout/Button'
import Image from 'next/image'
import {Intro} from 'layout/Intro'
import React from 'react'
import {ShopNotification} from 'blocks/fullwidth/shop/Shop'
import {Title} from 'layout/Title'
import css from './Product.module.scss'
import {fromModule} from 'util/styler/Styler'
import {isBrowser} from 'browser-or-node'
import {useTranslation} from 'locale/translate'

const styles = fromModule(css)

export const Product: React.FC<DPProductNode> = (product) => {
	const {contentBlocks, fullBlocks} = splitContent(product.blocks)
	const t = useTranslation()

	return (
		<div className={styles.product()}>
			<BasketContextProvider products={[product]}>
				<Hero className={styles.product.hero()}>
					<div className={styles.product.hero.content()}>
						<HeroBack url={product.parent?.url}>{t.product.show_all}</HeroBack>
						<HeroTitle>{product.title}</HeroTitle>
					</div>
				</Hero>
				<Content>
					<ContentBlocks>
						<ProductNotifications product={product} />
						<Intro description={product.description} />
						<Paragraphs blocks={contentBlocks} />
					</ContentBlocks>
					<ContentSidebar mod="stickout">
						<ProductInfo product={product} />
					</ContentSidebar>
				</Content>
				<Blocks>
					<Paragraphs blocks={fullBlocks} />
				</Blocks>
			</BasketContextProvider>
		</div>
	)
}

const ProductInfo: React.FC<{product: DPProduct}> = ({product}) => {
	const {image, selling_price} = product
	const t = useTranslation()

	if (!product) return null
	return (
		<div className={styles.info()}>
			{image?.src && (
				<div className={styles.info.image()}>
					<Image
						src={image.src}
						alt={image?.alt}
						layout="fill"
						objectFit="cover"
						sizes="(max-width: 1024px) 100vw, 340px"
					/>
				</div>
			)}
			<div className={styles.info.content()}>
				<Title.Marked className={styles.info.content.title()}>
					{t.product.price}
				</Title.Marked>
				{selling_price && (
					<p className={styles.info.content.price()}>
						{'€' + selling_price.toFixed(2).toString().replace(/\./g, ',')}
					</p>
				)}
				{<p className={styles.info.content.subtext()}>{t.product.subtext}</p>}
				<div className={styles.info.content.button()}>
					<ProductCartbutton product={product} />
				</div>
			</div>
		</div>
	)
}

const ProductNotifications: React.FC<{product: DPProduct}> = ({product}) => {
	const {basket} = useBasket()
	const productModel = basket.products.find(
		(prod) => prod.ui.node_id === product.node_id
	)

	return (
		<div id="shop_notifications">
			{productModel?.isSelected && <ShopNotification product={product} />}
		</div>
	)
}

export const ProductCartbutton: React.FC<{product: DPProduct}> = ({
	product
}) => {
	const {basket, storeBasket, addNotification} = useBasket()
	//const [addedProductTransition, setAddedProductTransition] = useState<boolean>(false)
	const t = useTranslation()
	const productModel = basket.products.find(
		(prod) => prod.ui.node_id === product.node_id
	)

	const addProduct = () => {
		if (!productModel || productModel.isSelected) return
		storeBasket(productModel.addToBasket())
		addNotification({
			id: Math.random(),
			product: productModel,
			key: 'product_added'
		})
	}

	if (!productModel) return null
	if (!isBrowser) return null

	return (
		<Button
			onClick={addProduct}
			iconafter={productModel.isSelected ? 'checkmark' : 'add'}
			className={styles.cartbutton.btn
				.is({saving: productModel.isSelected})
				.is({disabled: productModel.isSelected})()}
		>
			{productModel.isSelected ? t.product.added : t.product.add}
		</Button>
	)
}
