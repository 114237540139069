import {SidebarBlock} from 'blocks/SidebarBlock'
import {LinkStyled} from 'layout/LinkStyled'
import React from 'react'
import {DPSidebarLinksBlock} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import css from './SidebarLinks.module.scss'

const styles = fromModule(css)

export const SidebarLinks: React.FC<DPSidebarLinksBlock> = ({links}) => {
	if (!links || links.length === 0) return null

	return (
		<SidebarBlock type="sidebar_links" className={styles.sidebarlinks()}>
			<div className={styles.sidebarlinks.items()}>
				{links.map((link, i) => (
					<div key={i} className={styles.sidebarlinks.items.item()}>
						<LinkStyled to={link.url} mod="large">
							{link.title}
						</LinkStyled>
					</div>
				))}
			</div>
		</SidebarBlock>
	)
}
