import {Block} from 'blocks/Block'
import {Articlecard} from 'layout/cards/Articlecard'
import {Container} from 'layout/Container'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import React from 'react'
import {DPNewsflashBlock} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import css from './Newsflash.module.scss'

const styles = fromModule(css)

export const Newsflash: React.FC<DPNewsflashBlock> = ({
	title_left,
	title_right,
	articles_left,
	articles_right,
	link_left,
	link_right
}) => {
	if (articles_left?.length === 0 && articles_right?.length === 0) return null

	return (
		<Block type="newsflash" title={title_left} className={styles.newsflash()}>
			<Container>
				<div className={styles.newsflash.row()}>
					<div className={styles.newsflash.row.col()}>
						<Title.Marked className={styles.newsflash.title()}>
							{title_left}
						</Title.Marked>
						{articles_left?.length > 0 && (
							<div className={styles.newsflash.items()}>
								{articles_left.map((article) => (
									<div
										key={article.url}
										className={styles.newsflash.items.item()}
									>
										<Articlecard {...article} />
									</div>
								))}
							</div>
						)}
						{link_left && (
							<LinkStyled
								to={link_left.url}
								iconafter="arrow_right"
								className={styles.newsflash.link()}
							>
								{link_left.title}
							</LinkStyled>
						)}
					</div>
					<div className={styles.newsflash.row.col()}>
						<Title.Marked className={styles.newsflash.title()}>
							{title_right}
						</Title.Marked>
						{articles_right?.length > 0 && (
							<div className={styles.newsflash.items()}>
								{articles_right.map((article) => (
									<div
										key={article.url}
										className={styles.newsflash.items.item()}
									>
										<Articlecard {...article} />
									</div>
								))}
							</div>
						)}
						{link_right && (
							<LinkStyled
								to={link_right.url}
								iconafter="arrow_right"
								className={styles.newsflash.link()}
							>
								{link_right.title}
							</LinkStyled>
						)}
					</div>
				</div>
			</Container>
		</Block>
	)
}
