import {Paragraphs, splitContent} from 'blocks/Block'
import {SidebarParagraph} from 'blocks/SidebarBlock'
import {Blocks} from 'layout/content/Blocks'
import {Content, ContentBlocks, ContentSidebar} from 'layout/content/Content'
import {Hero, HeroBack, HeroTitle} from 'layout/Hero'
import {Intro} from 'layout/Intro'
import React from 'react'
import {DPConceptNode} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import css from './Concept.module.scss'

const styles = fromModule(css)

export const Concept: React.FC<DPConceptNode> = ({
	title,
	roadmap,
	description,
	logo,
	sidebar,
	blocks
}) => {
	const {contentBlocks, fullBlocks} = splitContent(blocks)

	return (
		<div>
			<Hero className={styles.concept.hero()}>
				<div className={styles.concept.hero.content()}>
					<HeroBack url={roadmap.url}>{roadmap.title}</HeroBack>
					<HeroTitle mod="concept" logo={logo}>
						{title}
					</HeroTitle>
				</div>
			</Hero>
			<Content>
				<ContentBlocks>
					<Intro description={description} />
					<Paragraphs blocks={contentBlocks} />
				</ContentBlocks>
				<ContentSidebar mod={logo ? 'stickout' : null}>
					{sidebar.map((bl, i) => (
						<SidebarParagraph {...bl} key={i} />
					))}
				</ContentSidebar>
			</Content>
			<Blocks>
				<Paragraphs blocks={fullBlocks} />
			</Blocks>
		</div>
	)
}
