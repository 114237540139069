import {Block} from 'blocks/Block'
import {ContactCard} from 'layout/cards/ContactCard'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import React from 'react'
import {DPContactsBlock} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import css from './Contacts.module.scss'

const styles = fromModule(css)

export const Contacts: React.FC<DPContactsBlock> = ({
	title,
	contacts,
	link
}) => {
	if (!contacts || contacts.length === 0) return null

	return (
		<Block type="contacts" title={title} className={styles.contacts()}>
			{title && (
				<Title.Marked className={styles.contacts.title()}>{title}</Title.Marked>
			)}
			<div className={styles.contacts.items()}>
				{contacts.map((contact, i) => (
					<div key={i} className={styles.contacts.items.item()}>
						<ContactCard {...contact} />
					</div>
				))}
			</div>
			{link?.url && (
				<LinkStyled
					to={link.url}
					iconafter="arrow_right"
					className={styles.contacts.link()}
				>
					{link.title}
				</LinkStyled>
			)}
		</Block>
	)
}
