import React, {HTMLProps} from 'react'
import {Mod} from 'types/basictypes'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import {Icon} from './Icon'
import css from './LinkStyled.module.scss'

const styles = fromModule(css)

type LinkModType = {
	mod?: Mod<'small' | 'large' | 'inherit'>
}

type LinkIconType = 'arrow_left' | 'arrow_right' | 'arrow_right_large'

export const LinkStyled: React.FC<
	HTMLProps<HTMLAnchorElement> &
		LinkModType & {
			to?: string
			iconbefore?: LinkIconType
			iconafter?: LinkIconType
		}
> = ({to, mod, iconbefore, iconafter, children, ...props}) => {
	return (
		<div className={styles.linkstyled.with(props.className)()}>
			<Link
				{...(props as any)}
				to={to}
				className={styles.linkstyled.link.mod(mod)()}
			>
				{iconbefore && <LinkStyledIcon icon={iconbefore} />}
				<LinkStyledText>{children}</LinkStyledText>
				{iconafter && <LinkStyledIcon icon={iconafter} />}
			</Link>
		</div>
	)
}

const LinkStyledText: React.FC = ({children}) => (
	<span className={styles.linkstyled.link.text()}>
		<span className={styles.linkstyled.link.text.label()}>{children}</span>
	</span>
)

const LinkStyledIcon: React.FC<{
	icon: LinkIconType
}> = ({icon}) => (
	<span className={styles.linkstyled.link.icon.mod(icon)()}>
		<Icon icon={icon} />
	</span>
)
