import {DPDocument, DPDocumentsBlock} from 'types/DPTypes'

import {Block} from 'blocks/Block'
import {Link} from 'util/link'
import React from 'react'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Documents.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Documents: React.FC<DPDocumentsBlock> = ({title, text, files}) => {
	return (
		<Block type="documents" title={title} className={styles.documents()}>
			{title && (
				<Title.Marked className={styles.documents.title()}>
					{title}
				</Title.Marked>
			)}
			{text && <Wysiwyg className={styles.documents.text()}>{text}</Wysiwyg>}
			{files && files.length > 0 && (
				<div className={styles.documents.items()}>
					{files.map((file, i) => (
						<div key={i} className={styles.documents.items.item()}>
							<DocumentsItem {...file} />
						</div>
					))}
				</div>
			)}
		</Block>
	)
}

const DocumentsItem: React.FC<DPDocument> = ({name, url, size}) => {
	return (
		<Link to={url} target="_blank" className={styles.item()}>
			<span className={styles.item.icon()}>
				<DocumentsIcon />
			</span>
			<div className={styles.item.content()}>
				<p className={styles.item.content.title()}>{name}</p>
				<p className={styles.item.content.extension()}>
					{getFileExtension(url)} ({sizeOf(size)})
				</p>
			</div>
		</Link>
	)
}

function sizeOf(bytes) {
	if (bytes === 0) {
		return '0.00 B'
	}
	var e = Math.floor(Math.log(bytes) / Math.log(1024))
	return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B'
}

function getFileExtension(filename) {
	const ext = /^.+\.([^.]+)$/.exec(filename)
	return ext === null ? '' : ext[1]
}

const DocumentsIcon = () => (
	<svg
		className={styles.icon()}
		xmlns="http://www.w3.org/2000/svg"
		width="39"
		height="47"
		viewBox="0 0 39 47"
	>
		<path className={styles.icon.block()} d="M38,46H1V1H30l8,8Z" />
		<path
			className={styles.icon.download()}
			d="M20,29.07l3.83-3.88a.76.76,0,0,0,0-1.05.74.74,0,0,0-1,0h0l-2.57,2.6V15a.74.74,0,0,0-.71-.76.73.73,0,0,0-.76.71V26.75l-2.57-2.6a.73.73,0,0,0-1,0h0a.74.74,0,0,0,0,1.05l3.82,3.88a.74.74,0,0,0,1,0Z"
		/>
		<path
			className={styles.icon.download()}
			d="M14.69,31.23h9.62a.76.76,0,0,1,.75.75h0a.75.75,0,0,1-.75.75H14.69a.75.75,0,0,1-.75-.75h0A.76.76,0,0,1,14.69,31.23Z"
		/>
	</svg>
)
