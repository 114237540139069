import { useTranslation } from 'locale/translate'
import { DPArticle } from 'types/DPTypes'
import { dpDateToJsDate } from 'util/dates'
import { fromModule } from 'util/styler/Styler'
import css from './Articlecard.module.scss'
import { CardImage, CardTitle } from './Card'

const styles = fromModule(css)

export const Articlecard: React.FC<DPArticle> = ({url, image, date, title}) => {
	const t = useTranslation()
	const jsDate = dpDateToJsDate(date)
	const day = jsDate?.getDate()
	const month = jsDate && t.months[jsDate.getMonth()]
	const year = jsDate?.getFullYear()

	return (
		<div className={styles.articlecard()}>
			<div className={styles.articlecard.row()}>
				<CardImage
					image={image}
					url={url}
					className={styles.articlecard.image()}
				/>
				<div className={styles.articlecard.content()}>
					{date && (
						<div className={styles.articlecard.content.date()}>
							{`${day <= 9 ? `0${day}` : day} ${month} ${year}`}
						</div>
					)}
					<CardTitle
						as="h4"
						title={title}
						url={url}
						className={styles.articlecard.content.title()}
					/>
				</div>
			</div>
		</div>
	)
}
