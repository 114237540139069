import {Block} from 'blocks/Block'
import {Personcard} from 'layout/cards/PersonCard'
import {Container} from 'layout/Container'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import React from 'react'
import {DPFullcontactsBlock} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import css from './FullContacts.module.scss'

const styles = fromModule(css)

export const FullContacts: React.FC<DPFullcontactsBlock> = ({
	title,
	text,
	contacts
}) => {
	if (!contacts || contacts.length === 0) return null

	return (
		<Block type="fullcontacts" id="contacts" className={styles.fullcontacts()}>
			<Container>
				{title && (
					<Title.Marked className={styles.fullcontacts.title()}>
						{title}
					</Title.Marked>
				)}
				{text && (
					<Wysiwyg className={styles.fullcontacts.text()}>{text}</Wysiwyg>
				)}
				<div className={styles.fullcontacts.items()}>
					{contacts.map((contact, i) => (
						<div key={i} className={styles.fullcontacts.items.item()}>
							<Personcard {...contact} />
						</div>
					))}
				</div>
			</Container>
		</Block>
	)
}
