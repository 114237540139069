import {DPSidebarBannerBlock} from 'types/DPTypes'
import {Image} from 'layout/Image'
import React from 'react'
import {SidebarBlock} from 'blocks/SidebarBlock'
import css from './SidebarBanner.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const SidebarBanner: React.FC<DPSidebarBannerBlock> = ({
	title,
	banners
}) => {
	if (!banners || banners.length === 0) return null

	return (
		<SidebarBlock type="sidebar_banner" className={styles.sidebarbanner()}>
			<p className={styles.sidebarbanner.title()}>{title}</p>
			{banners.map((banner, i) => {
				if (!banner || !banner.image?.src) return null

				return (
					<div key={i} className={styles.sidebarbanner.item()}>
						{banner.image?.src && (
							<Image
								{...banner.image}
								className={styles.sidebarbanner.item.image()}
								sizes="340px"
							/>
						)}
					</div>
				)
			})}
		</SidebarBlock>
	)
}
