import {Card, CardTitle} from './Card'
import React, {useEffect, useState} from 'react'

import {DPContact} from 'types/DPTypes'
import {Image} from 'layout/Image'
import {Link} from 'util/link'
import {SocialLink} from 'layout/Social'
import css from './PersonCard.module.scss'
import {deobfuscate} from 'util/obfuscate'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Personcard: React.FC<DPContact> = ({
	url,
	title,
	function: jobtitle,
	image,
	phone,
	email,
	linkedin
}) => {
	const [contactEmail, setContactEmail] = useState(null)
	const [contactPhone, setContactPhone] = useState(null)

	useEffect(() => {
		if (email) setContactEmail(deobfuscate(email))
		if (phone) setContactPhone(deobfuscate(phone))
	}, [email, phone])

	if (!title) return null

	return (
		<Card className={styles.personcard()}>
			<div className={styles.personcard.top()}>
				<div className={styles.personcard.top.left()}>
					<CardTitle title={title} url={url} />
					<p className={styles.personcard.top.left.subtitle()}>{jobtitle}</p>
				</div>
				<div className={styles.personcard.top.right()}>
					{image?.src && (
						<Link to={url} className={styles.personcard.top.right.image()}>
							<Image {...image} format="avatar" />
						</Link>
					)}
				</div>
			</div>
			<div className={styles.personcard.content()}>
				{phone && contactPhone && <p>{contactPhone}</p>}
				{email && contactEmail && (
					<a
						href={`mailto:${contactEmail}`}
						className={styles.personcard.content.link()}
					>
						{contactEmail}
					</a>
				)}
			</div>
			{linkedin && (
				<div className={styles.personcard.social()}>
					<SocialLink type="linkedin" url={linkedin} mod="round" />
				</div>
			)}
		</Card>
	)
}
