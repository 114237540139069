import {Block} from 'blocks/Block'
import {Articlecard} from 'layout/cards/Articlecard'
import {Container} from 'layout/Container'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {useTranslation} from 'locale/translate'
import React, {useContext} from 'react'
import {DPArticlesBlock} from 'types/DPTypes'
import {GeneralContext} from 'types/general'
import {fromModule} from 'util/styler/Styler'
import css from './Articles.module.scss'

const styles = fromModule(css)

export const Articles: React.FC<DPArticlesBlock> = ({title, articles}) => {
	const {links} = useContext(GeneralContext)
	const t = useTranslation()
	if (articles?.length === 0) return null

	return (
		<Block type="articles" title={title} className={styles.articles()}>
			<Container>
				{title && (
					<Title.Marked className={styles.articles.title()}>
						{title}
					</Title.Marked>
				)}
				<div className={styles.articles.items()}>
					{articles.map((article, i) => (
						<div key={i} className={styles.articles.items.item()}>
							<Articlecard {...article} />
						</div>
					))}
				</div>
				<LinkStyled
					to={links.articles}
					iconafter="arrow_right"
					className={styles.articles.link()}
				>
					{t.article.show_all}
				</LinkStyled>
			</Container>
		</Block>
	)
}
