export const obfuscate = (string: string): string => {
	if (!string) return null

	return string
		.replace('@', '[a]')
		.replace(/\./g, '...')
		.replace(/1/g, 'One')
		.replace(/2/g, 'Two')
		.replace(/3/g, 'Three')
		.replace(/4/g, 'Four')
		.replace(/5/g, 'Five')
		.replace(/6/g, 'Six')
		.replace(/7/g, 'Seven')
		.replace(/8/g, 'Eight')
		.replace(/9/g, 'Nine')
		.replace(/0/g, 'Zero')
}

export const deobfuscate = (string: string): string => {
	if (!string) return null

	return string
		.replace('[a]', '@')
		.replace(/\.\.\./g, '.')
		.replace(/One/g, '1')
		.replace(/Two/g, '2')
		.replace(/Three/g, '3')
		.replace(/Four/g, '4')
		.replace(/Five/g, '5')
		.replace(/Six/g, '6')
		.replace(/Seven/g, '7')
		.replace(/Eight/g, '8')
		.replace(/Nine/g, '9')
		.replace(/Zero/g, '0')
}
