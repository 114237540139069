import { Image } from 'layout/Image'
import { LinkStyled } from 'layout/LinkStyled'
import { Title } from 'layout/Title'
import { useTranslation } from 'locale/translate'
import { DPProgram, DPProject } from 'types/DPTypes'
import { Link } from 'util/link'
import { fromModule } from 'util/styler/Styler'
import css from './Projectcard.module.scss'

const styles = fromModule(css)

export const Projectcard: React.FC<{
	project: DPProject
}> = ({project}) => {
	const t = useTranslation()

	return (
		<div className={styles.projectcard()}>
			<Link to={project.url} className={styles.projectcard.visual()}>
				{project.image && (
					<Image
						{...project.image}
						format="teaser"
					/>
				)}
				<div className={styles.projectcard.visual.overlay()} />
				<div className={styles.projectcard.visual.content()}>
					<ProjectcardCategory program={project.program} />
					<Title.H4
						className={styles.projectcard.visual.content.title()}
						mod={['bold', 'inherit']}
					>
						{project.title}
					</Title.H4>
				</div>
			</Link>
			<LinkStyled
				to={project.url}
				iconafter="arrow_right_large"
				className={styles.projectcard.link()}
				mod="large"
			>
				{t.project.discover}
			</LinkStyled>
		</div>
	)
}

const ProjectcardCategory: React.FC<{program: DPProgram}> = ({program}) => {
	if (!program) return null
	const colorcode = program.color?.colorcode

	return (
		<div className={styles.category()} style={{backgroundColor: colorcode}}>
			{program.title}
		</div>
	)
}
