import {Block, BlockContext} from 'blocks/Block'
import {DPColorTerm, DPLink, DPLinksblockBlock} from 'types/DPTypes'
import React, {useContext, useState} from 'react'

import {Icon} from 'layout/Icon'
import {Link} from 'util/link'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Linksblock.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Linksblock: React.FC<DPLinksblockBlock> = ({
	title,
	text,
	items
}) => {
	const {fullwidth} = useContext(BlockContext)
	if (!title && (!items || items.length === 0)) return null

	return (
		<Block
			type="linksblock"
			title={title}
			className={styles.linksblock.mod({fullwidth})()}
		>
			{title && (
				<Title.Marked className={styles.linksblock.title()}>
					{title}
				</Title.Marked>
			)}
			{text && <Wysiwyg className={styles.linksblock.text()}>{text}</Wysiwyg>}
			{items && items.length > 0 && (
				<div className={styles.linksblock.items()}>
					{items.map((item) => (
						<div key={item.id} className={styles.linksblock.items.item()}>
							<LinksItem {...item} />
						</div>
					))}
				</div>
			)}
		</Block>
	)
}

export const LinksItem: React.FC<{
	link: DPLink
	color?: DPColorTerm
}> = ({link, color}) => {
	const [isHovered, setHovered] = useState(false)
	if (!link?.url) return null

	let linkStyle
	if (isHovered && color?.colorcode) {
		linkStyle = {backgroundColor: color.colorcode}
	}

	return (
		<Link
			to={link.url}
			className={styles.item()}
			onMouseOver={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			style={linkStyle}
		>
			<span className={styles.item.title()}>{link.title || link.url}</span>
			<span className={styles.item.icon()}>
				<Icon icon="arrow_right" />
			</span>
		</Link>
	)
}
