import { Block } from 'blocks/Block'
import { CardImage } from 'layout/cards/Card'
import { Title } from 'layout/Title'
import React from 'react'
import { DPRoadmapsBlock } from 'types/DPTypes'
import { Link } from 'util/link'
import { fromModule } from 'util/styler/Styler'
import css from './Roadmaps.module.scss'

const styles = fromModule(css)

export const Roadmaps: React.FC<DPRoadmapsBlock> = ({title, roadmaps}) => {
	if (!roadmaps || !roadmaps.length) return null

	return (
		<Block type="roadmaps" title={title} className={styles.roadmaps()}>
			{title && (
				<Title.Marked className={styles.roadmaps.title()}>{title}</Title.Marked>
			)}
			<div className={styles.roadmaps.items()}>
				{roadmaps.map((roadmap) => (
					<div key={roadmap.node_id} className={styles.roadmaps.items.item()}>
						<CardImage image={roadmap.image} url={roadmap.url} />
						<h5 className={styles.roadmaps.items.item.title()}>
							<Link to={roadmap.url}>{roadmap.title}</Link>
						</h5>
					</div>
				))}
			</div>
		</Block>
	)
}
