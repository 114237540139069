import {HeroTitle} from 'layout/Hero'
import {Icon} from 'layout/Icon'
import React from 'react'
import {DPProgramNode} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import {Page} from './Page'
import css from './Program.module.scss'

const styles = fromModule(css)

export const Program: React.FC<DPProgramNode> = (page) => {
	return <Page parent={null} tags={[]} color={null} {...page} />
}

export const ProgramTitlerow: React.FC<{color?: string; title: string}> = ({
	color,
	title
}) => {
	return (
		<div className={styles.titlerow()}>
			<div className={styles.titlerow.icon()}>{switchProgramIcon(color)}</div>
			<HeroTitle>{title}</HeroTitle>
		</div>
	)
}

export function switchProgramIcon(colorcode) {
	switch (true) {
		case colorcode === '#e75924':
			return <Icon icon="new_shifting" />
		case colorcode === '#90abd3':
			return <Icon icon="personalised_foods" />
		case colorcode === '#6c2016':
			return <Icon icon="resilient" />
		case colorcode === '#c4b628':
			return <Icon icon="world_class" />
		default:
			return <Icon icon="resilient" />
	}
}
