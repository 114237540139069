import {DPImage, DPUrl} from 'types/DPTypes'
import NextImage, {ImageProps} from 'next/image'

import {Mod} from 'types/basictypes'
import React from 'react'
import css from './Image.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export type Imageformat = 'avatar' | 'teaser' | 'landscape_thumbnail'

const myLoader = ({src, width, quality}) => {
	if (src.includes('/styles/')) {
		return src
	}
	return src.replace('/files/', `/files/styles/next_${width}/public/`)
}

export const Image: React.FC<
	| DPImage &
			Omit<ImageProps, 'src'> & {
				src: string
				sizes?: string
				mod?: Mod<'portrait' | 'centered'>
				format?: Imageformat
				className?: string
			}
> = (props) => {
	if (!props.src) return null

	if ('format' in props) {
		const {format, ...otherProps} = props
		switch (format) {
			case 'avatar':
				return (
					<Image
						{...otherProps}
						src={injectStyleInSrc(props.src, 'avatar')}
						width={200}
						height={200}
						unoptimized
					/>
				)
			case 'teaser':
				return (
					<Image
						{...otherProps}
						src={injectStyleInSrc(props.src, 'teaser')}
						width={520}
						height={520}
						unoptimized
					/>
				)
			case 'landscape_thumbnail':
				return (
					<Image
						{...otherProps}
						src={injectStyleInSrc(props.src, 'landscape_thumbnail')}
						width={215}
						height={136}
						unoptimized
					/>
				)
		}
	}

	const {className, mod, ...imgProps} = props

	const blurred = getBlurredUrl(imgProps.src)
	if (blurred) {
		imgProps.placeholder = 'blur'
		imgProps.blurDataURL = blurred
	}

	return (
		<div className={styles.image.with(className).mod(mod)()}>
			<NextImage priority {...(imgProps as any)} loader={myLoader} />
		</div>
	)
}

const getBlurredUrl = (src: DPUrl): DPUrl | false => {
	if (src.includes('/files/styles/avatar')) {
		return src.replace('/files/styles/avatar', '/files/styles/blurry_square')
	}
	if (src.includes('/files/styles/teaser')) {
		return src.replace('/files/styles/teaser', '/files/styles/blurry_square')
	}
	if (src.includes('/files/styles/landscape_thumbnail')) {
		return src.replace(
			'/files/styles/landscape_thumbnail',
			'/files/styles/blurry'
		)
	}
	if (src.includes('/files/styles')) {
		return false
	}
	return src.replace('/files/', `/files/styles/blurry/public/`)
}

const injectStyleInSrc = (src: DPUrl, imageStyle: Imageformat): DPUrl => {
	const result = src.replace('/files/', `/files/styles/${imageStyle}/public/`)
	return result
}
