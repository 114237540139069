import {
	Configure as ConfigureAlgolia,
	ConfigureProps,
	Hits,
	InstantSearch,
	useClearRefinements,
	useInstantSearch,
	usePagination
} from 'react-instantsearch-hooks-web'
import React, {useContext, useEffect, useRef, useState} from 'react'

import AnimateHeight from 'react-animate-height'
import {ArticleRow} from './rows/ArticleRow'
import {Button} from 'layout/Button'
import {EventRow} from './rows/EventRow'
import {GeneralContext} from 'types/general'
import {Icon} from 'layout/Icon'
import {InsightsSearchClickEvent} from 'search-insights/dist/click'
import {LinkStyled} from 'layout/LinkStyled'
import {NodeRow} from './rows/NodeRow'
import {ProjectRow} from './rows/ProjectRow'
import {SearchOptions} from '@algolia/client-search'
import {Title} from 'layout/Title'
import aa from 'search-insights'
import algoliasearch from 'algoliasearch/lite'
import css from './Overview.module.scss'
import {fromModule} from 'util/styler/Styler'
import {history} from 'instantsearch.js/cjs/lib/routers'
import {isBrowser} from 'browser-or-node'
import {useRouter} from 'next/dist/client/router'
import {useTranslation} from 'locale/translate'

const styles = fromModule(css)
const index = process.env.NEXT_PUBLIC_ALGOLIA_INDEX || 'demo_search'
const algoliaId = process.env.NEXT_PUBLIC_ALGOLIA_ID || 'K7SK5EXOG3'
const algoliaKey =
	process.env.NEXT_PUBLIC_ALGOLIA_PUBLIC_KEY ||
	'f92e590a2f2ed546a4ed5058da5c3afc'
const itemsPerPage = 15

const createSearchClient = (default_empty: boolean, initial: any) => {
	const searchClient = algoliasearch(algoliaId, algoliaKey)

	return {
		search(requests) {
			if (default_empty && requests.every(({params}) => !params.query)) {
				return Promise.resolve({
					results: requests.map(() => ({
						hits: [],
						nbHits: 0,
						nbPages: 0,
						page: 0,
						processingTimeMS: 0
					}))
				})
			}
			if (
				initial &&
				requests.length === 1 &&
				(!requests[0].params.page || requests[0].params.page === 0) &&
				!requests[0].params.query &&
				!requests[0].params.tagFilters &&
				!requests[0].params.numericFilters
			) {
				return Promise.resolve(initial)
			}
			return searchClient.search(requests).then((res) => {
				return res
			})
		},
		searchForFacetValues(requests) {
			return searchClient.searchForFacetValues(requests)
		}
	}
}

const useSearchParamsAreLoaded = () => {
	const [isLoaded, setIsLoaded] = useState(false)
	const router = useRouter()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (!isBrowser) return
		if (isLoaded) return
		if (!window.location.search) {
			setIsLoaded(true)
			return
		}

		const queryKeys = Object.keys(router.query)
		if (!queryKeys.length) return
		if (queryKeys.length === 1 && queryKeys[0] === 'slug') return
		setIsLoaded(true)
	})
	return isLoaded
}

const routing = (index, push) => ({
	router: history({
		push: (url) => {
			const search = new URLSearchParams(location.search)
			if (search.get('tab')) {
				const strTab = url.includes('?') ? '&' : '?'
				url += strTab + 'tab=' + search.get('tab')
			}
			push(url, undefined, {shallow: true})
		}
	}),
	stateMapping: {
		stateToRoute(uiState) {
			const newRouteState = {}
			const stateObj = uiState[index]
			for (const stateKey of Object.keys(stateObj)) {
				switch (stateKey) {
					case 'configure':
						break
					case 'hierarchicalMenu':
					case 'refinementList':
					case 'multiRange':
					case 'numericMenu':
					case 'query':
					case 'page':
						newRouteState[stateKey] = stateObj[stateKey]
				}
			}
			return newRouteState
		},
		routeToState(routeState) {
			const newUiState = {[index]: {}}
			for (const routeKey of Object.keys(routeState)) {
				switch (routeKey) {
					case 'hierarchicalMenu':
					case 'refinementList':
					case 'multiRange':
					case 'numericMenu':
					case 'query':
					case 'page':
						newUiState[index][routeKey] = routeState[routeKey]
				}
			}
			return newUiState
		}
	}
})

export const Overview: React.FC<{
	types?: Array<string>
	future_dates?: boolean
	index_postfix?: string
	default_empty?: boolean
	initial: any
}> = ({
	types,
	future_dates,
	index_postfix,
	default_empty,
	initial,
	children
}) => {
	const {lang} = useContext(GeneralContext)
	const router = useRouter()
	const today = Math.round(Date.now() / 1000)
	let filters = `language:${lang}`
	if (future_dates) filters = `date_timestamp > ${today} AND ` + filters
	if (types && types.length) {
		filters += ' AND ' + types.map((type) => 'type:' + type).join(' OR ')
	}
	const [customSearchClient, setCustomSearchClient] = useState(() =>
		createSearchClient(default_empty, initial)
	)
	const refContainer = useRef()
	useEffect(() => {
		setCustomSearchClient(() => createSearchClient(default_empty, initial))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initial])

	useEffect(() => {
		if (!refContainer.current) return
		const refDom = refContainer.current as HTMLDivElement
		const refRect = refDom.getBoundingClientRect()
		refDom.style.minHeight = `calc(100vh - 50px - ${refRect.top}px)`
	}, [refContainer])

	useEffect(() => {
		aa('init', {
			appId: algoliaId,
			apiKey: algoliaKey,
			anonymousUserToken: true
		})
	}, [])

	const isHydrated = useSearchParamsAreLoaded()

	return (
		<OverviewErrorBoundary>
			<div ref={refContainer} style={{minHeight: 'calc(100vh - 50px)'}}>
				<InstantSearch
					searchClient={customSearchClient}
					indexName={index + (index_postfix || '')}
					routing={routing(index + (index_postfix || ''), router.push)}
					insights={{
						insightsClient: aa,
						onEvent: (event, client) => {
							const {insightsMethod, eventType, payload} = event
							if (!insightsMethod) return
							if (eventType !== 'click') {
								client(insightsMethod, payload as any)
								return
							}
							const {queryID} = payload as InsightsSearchClickEvent
							if (!queryID) return
							client(insightsMethod, payload as InsightsSearchClickEvent)
						}
					}}
				>
					<Configure filters={filters} hitsPerPage={itemsPerPage} />
					<div className={styles.overview.is({hydrated: isHydrated})()}>
						{children}
					</div>
				</InstantSearch>
			</div>
		</OverviewErrorBoundary>
	)
}

const Configure: React.FC<ConfigureProps & SearchOptions> = (props) => (
	<ConfigureAlgolia {...props} />
)

export const OverviewTop: React.FC<{notabs?: boolean}> = ({
	notabs,
	children
}) => {
	const {refine} = usePagination()
	const [pageIsSet, setPageIsSet] = useState<boolean>(false)
	const router = useRouter()
	const {page} = router.query

	useEffect(() => {
		if (pageIsSet || !page) return
		const intPage = parseInt(typeof page === 'string' ? page : page[0])
		if (intPage > 1) {
			setTimeout(() => {
				refine(intPage - 1)
				setPageIsSet(true)
			}, 200)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page])

	return (
		<div className={styles.overview_top()}>
			<div className={styles.overview_top.row()}>
				<div className={styles.overview_top.tabs()}>{children}</div>
				<div className={styles.overview_top.pagination()}>
					<OverviewPagination notabs={notabs} />
				</div>
			</div>
		</div>
	)
}

export const OverviewBottom: React.FC = () => {
	return (
		<div className={styles.overview_bottom()}>
			<div className={styles.overview_bottom.pagination()}>
				<OverviewPagination />
			</div>
		</div>
	)
}

export const OverviewTab: React.FC<{
	isActive: boolean
	tab?: string
}> = ({isActive, children, tab}) => {
	const router = useRouter()
	const {slug} = router.query
	const {refine: clearFilters} = useClearRefinements()
	return (
		<Button
			onClick={() => {
				clearFilters()
				router.push({query: {slug, tab}}, undefined, {shallow: true})
			}}
			className={styles.overview_tab()}
			mod={{grey: !isActive}}
		>
			{children}
		</Button>
	)
}

export const OverviewPagination: React.FC<{notabs?: boolean}> = ({notabs}) => {
	const {nbHits, isFirstPage, isLastPage, refine, currentRefinement} =
		usePagination()
	const t = useTranslation().overview.pagination
	const {indexUiState, status} = useInstantSearch()

	const hasRefinements = () => {
		for (let configKey in indexUiState) {
			if (configKey === 'configure') continue
			return true
		}
		return false
	}

	const hide = !nbHits && !hasRefinements()

	return (
		<div className={styles.overview_pagination.mod({notabs})()}>
			{!isFirstPage && (
				<LinkStyled
					onClick={(e) => {
						e.preventDefault()
						refine(currentRefinement - 1)
					}}
					iconbefore="arrow_left"
					className={styles.overview_pagination.link.mod('previous')()}
					mod="large"
				>
					{t.previous}
				</LinkStyled>
			)}
			<p className={styles.overview_pagination.pages.mod({hide: hide})()}>
				<b>
					{nbHits > 0
						? `${itemsPerPage * currentRefinement + 1} - ${Math.min(
								itemsPerPage * (currentRefinement + 1),
								nbHits
						  )}`
						: '0'}
				</b>{' '}
				{t.of} {nbHits}
			</p>
			{!isLastPage && (
				<LinkStyled
					onClick={(e) => {
						e.preventDefault()
						refine(currentRefinement + 1)
					}}
					iconafter="arrow_right"
					className={styles.overview_pagination.link.mod('next')()}
					mod="large"
				>
					{t.next}
				</LinkStyled>
			)}
		</div>
	)
}

export const OverviewContent: React.FC = ({children}) => {
	return <div className={styles.overview_content()}>{children}</div>
}

export const OverviewFilters: React.FC = ({children}) => {
	const [isOpen, setOpen] = useState(true)
	const t = useTranslation().overview.filters

	return (
		<div className={styles.overview_filters()}>
			<div className={styles.overview_filters.top()}>
				<a
					onClick={() => setOpen(!isOpen)}
					className={styles.overview_filters.top.title()}
				>
					<p className={styles.overview_filters.top.title.label()}>{t.title}</p>
					<span
						className={styles.overview_filters.top.title.icon.is({
							open: isOpen
						})()}
					>
						<Icon icon="chevron_down" />
					</span>
				</a>
				<div className={styles.overview_filters.top.clear()}>
					<OverviewClear />
				</div>
			</div>
			<AnimateHeight height={isOpen ? 'auto' : 0}>{children}</AnimateHeight>
		</div>
	)
}

const OverviewClear: React.FC = () => {
	const {refine} = useClearRefinements()
	const t = useTranslation()
	return (
		<LinkStyled onClick={refine} mod="small">
			{t.overview.filters.clear}
		</LinkStyled>
	)
}

export const OverviewSearchLabel: React.FC = ({children}) => {
	return (
		<Title.H5
			style={{
				fontSize: '17px',
				borderTop: '1px solid #e8eef4',
				paddingTop: '30px',
				marginTop: '30px',
				marginBottom: '10px'
			}}
		>
			{children}
		</Title.H5>
	)
}

export const OverviewResults: React.FC<{
	label?: (amount: number) => string
}> = ({label, children}) => {
	const {nbHits} = usePagination()

	return (
		<div className={styles.overview_results()}>
			{nbHits > 0 && label && (
				<div className={styles.overview_results.label()}>{label(nbHits)}</div>
			)}
			<div className={styles.overview_results.items()}>
				<Hits hitComponent={OverviewHit} />
				{children}
			</div>
		</div>
	)
}

export const OverviewHit: React.FC<{
	hit: any
}> = ({hit}) => {
	const node = JSON.parse(hit.data)
	const hr = hit._highlightResult
	const getTitle = () => {
		if (!hr || !hr.title) return node.title
		return hr.title.value
	}
	const getDescription = () => {
		if (!('description' in node)) return null
		if (!hr || !hr.description) return node.description
		return hr.description.value
	}

	const nodeData = getDescription()
		? {...node, title: getTitle(), description: getDescription()}
		: {...node, title: getTitle()}

	switch (node.type) {
		case 'article':
			return <ArticleRow article={nodeData} />
		case 'event':
			return <EventRow event={nodeData} />
		case 'project':
			return <ProjectRow project={nodeData} />
	}

	return <NodeRow node={nodeData} />
}

class OverviewErrorBoundary extends React.Component<{}, {hasError: boolean}> {
	constructor(props) {
		super(props)
		this.state = {hasError: false}
	}

	static getDerivedStateFromError(error) {
		return {hasError: true}
	}
	componentDidCatch(error, errorInfo) {
		window.location.replace(location.pathname)
	}

	render() {
		if (this.state.hasError) return null
		return this.props.children
	}
}
