import {Block} from 'blocks/Block'
import {Container} from 'layout/Container'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import {useTranslation} from 'locale/translate'
import Image from 'next/image'
import React from 'react'
import {DPConcept, DPConceptsBlock, DPImage} from 'types/DPTypes'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import css from './Concepts.module.scss'

const styles = fromModule(css)

export const Concepts: React.FC<DPConceptsBlock> = ({
	title,
	text,
	concepts
}) => {
	if (!concepts?.length) return null

	return (
		<Block type="concepts" title={title} className={styles.concepts()}>
			<Container>
				{title && (
					<Title.Marked className={styles.concepts.title()}>
						{title}
					</Title.Marked>
				)}
				{text && <Wysiwyg className={styles.concepts.text()}>{text}</Wysiwyg>}
				<div className={styles.concepts.items()}>
					{concepts.map((item, i) => (
						<div key={i} className={styles.concepts.items.item()}>
							<ConceptsCard {...item} />
						</div>
					))}
				</div>
			</Container>
		</Block>
	)
}

const ConceptsCard: React.FC<DPConcept> = ({url, title, logo, description}) => {
	const t = useTranslation().concept

	return (
		<div className={styles.card()}>
			<Link to={url} className={styles.card.logo()}>
				<ConceptsLogo logo={logo} />
			</Link>
			<Title.H5 className={styles.card.title()}>
				<Link to={url}>{title}</Link>
			</Title.H5>
			<Wysiwyg className={styles.card.text()}>{description}</Wysiwyg>
			<LinkStyled
				to={url}
				iconafter="arrow_right_large"
				className={styles.card.link()}
				mod="large"
			>
				{t.readmore}
			</LinkStyled>
		</div>
	)
}

export const ConceptsLogo: React.FC<{
	logo: DPImage
	mod?: 'hero'
}> = ({logo, mod}) => {
	if (!logo?.src) return null
	return (
		<div className={styles.logo.mod(mod)()}>
			<Image {...logo} />
		</div>
	)
}
