import {Block} from 'blocks/Block'
import {Button} from 'layout/Button'
import {Container} from 'layout/Container'
import {Trusted} from 'layout/Trusted'
import {useTranslation} from 'locale/translate'
import React from 'react'
import {DPNewsletterBlock} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import css from './Newsletter.module.scss'

const styles = fromModule(css)

export const Newsletter: React.FC<DPNewsletterBlock> = ({
	mailchimp_link,
	mailchimp_userid,
	mailchimp_audienceid,
	description
}) => {
	const t = useTranslation().newsletter
	if (!mailchimp_link && !mailchimp_userid && !mailchimp_audienceid) return null

	return (
		<Block type="newsletter" id="newsletter" className={styles.newsletter()}>
			<Container>
				<div className={styles.newsletter.block()}>
					<div className={styles.newsletter.block.description()}>
						<Trusted>{description}</Trusted>
					</div>
					<form
						action={mailchimp_link}
						method="get"
						target="_blank"
						className={styles.newsletter.block.form()}
					>
						<input type="hidden" name="u" value={mailchimp_userid} />
						<input type="hidden" name="id" value={mailchimp_audienceid} />
						<input
							required
							type="email"
							name="MERGE0"
							placeholder={t.placeholder}
							className={styles.newsletter.block.form.input()}
						/>
						<Button
							as="button"
							className={styles.newsletter.block.form.submit()}
						>
							{t.button}
						</Button>
					</form>
				</div>
			</Container>
		</Block>
	)
}
