import {Block} from 'blocks/Block'
import {Membercard} from 'layout/cards/Membercard'
import {Container} from 'layout/Container'
import React from 'react'
import {DPFullmembersBlock} from 'types/DPTypes'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import css from './FullMembers.module.scss'

const styles = fromModule(css)

export const FullMembers: React.FC<DPFullmembersBlock> = ({members}) => {
	return (
		<Block type="fullmembers" id="members" className={styles.fullmembers()}>
			<Container>
				{Object.keys(members).map((letter) => (
					<div key={letter} id={letter} style={{marginBottom: '50px'}}>
						<div className={styles.fullmembers.category()}>{letter}</div>
						<div className={styles.fullmembers.items()}>
							{members[letter].map((member) => (
								<div
									key={member.key}
									className={styles.fullmembers.items.item()}
								>
									<Membercard {...member} />
								</div>
							))}
						</div>
					</div>
				))}
			</Container>
		</Block>
	)
}

export const FullMembersLinks: React.FC<DPFullmembersBlock> = ({members}) => {
	return (
		<div className={styles.links()}>
			{Object.keys(members).map((letter) => (
				<Link key={letter} to={`#${letter}`} className={styles.links.item()}>
					{letter}
				</Link>
			))}
		</div>
	)
}
