import {Block} from 'blocks/Block'
import {Share} from 'layout/Share'
import React from 'react'
import {DPShareblockBlock} from 'types/DPTypes'

export const Shareblock: React.FC<DPShareblockBlock> = ({title}) => {
	return (
		<Block type="shareblock" title={title}>
			<Share title={title} />
		</Block>
	)
}
