import {DPAnchorBlock, DPBlock} from 'types/DPTypes'
import React, {useContext} from 'react'

import {BlockContext} from 'blocks/Block'
import {GeneralContext} from 'types/general'

export const Anchor: React.FC<DPAnchorBlock> = ({html_id}) => {
	return null
}

export const useCurrentAnchorID = () => {
	const {node} = useContext(GeneralContext)
	const {block} = useContext(BlockContext)

	if (!node || !block) return null
	const blocks = flattenBlocks('blocks' in node ? node.blocks : [])

	const blockIndex = blocks.findIndex((bl) => bl.id === block?.id)
	const previousBlock = blocks[blockIndex - 1]

	if (previousBlock?.type === 'anchor') {
		return previousBlock.html_id
	}

	return null
}

const flattenBlocks = (blocks: DPBlock[]): DPBlock[] => {
	let result: DPBlock[] = []

	blocks.forEach((block) => {
		if (!block) return

		if (block.type === 'reusable') {
			result = [...result, ...flattenBlocks(block.blocks || [])]
		} else {
			result = [...result, block]
		}
	})

	return result
}
