import {Block} from 'blocks/Block'
import {DPLogosBlock} from 'types/DPTypes'
import Image from 'next/image'
import {Link} from 'util/link'
import React from 'react'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Logos.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Logos: React.FC<Omit<DPLogosBlock, 'type'>> = ({
	title,
	text,
	primary_companies,
	companies
}) => {
	const primaryCompaniesWithLogo = (primary_companies || []).filter(
		(comp) => comp.logo
	)
	const companiesWithLogo = (companies || []).filter((comp) => comp.logo)
	if (!companiesWithLogo.length && !primaryCompaniesWithLogo.length) return null

	return (
		<Block type="logos" id={title} className={styles.logos()}>
			{title && (
				<Title.Marked className={styles.logos.title()}>{title}</Title.Marked>
			)}
			{text && <Wysiwyg className={styles.logos.text()}>{text}</Wysiwyg>}
			{primaryCompaniesWithLogo?.length > 0 && (
				<div className={styles.logos.items()}>
					{primaryCompaniesWithLogo.map((company, i) => (
						<div key={i} className={styles.logos.items.item()}>
							<Link
								to={company.sf_website}
								className={styles.logos.items.item.logo.mod({
									sub: false
								})()}
							>
								<Image
									src={company.logo.src}
									alt={company.logo?.alt}
									height={100}
									width={(company.logo.width / company.logo.height) * 100}
								/>
							</Link>
						</div>
					))}
				</div>
			)}
			{companiesWithLogo?.length > 0 && (
				<div className={styles.logos.items()}>
					{companiesWithLogo.map((company) => (
						<div key={company.key} className={styles.logos.items.item()}>
							<Link
								to={company.sf_website}
								className={styles.logos.items.item.logo.mod({
									sub: true
								})()}
							>
								<Image
									src={company.logo.src}
									alt={company.logo?.alt}
									height={50}
									width={(company.logo.width / company.logo.height) * 50}
								/>
							</Link>
						</div>
					))}
				</div>
			)}
		</Block>
	)
}
