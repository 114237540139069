import {Block} from 'blocks/Block'
import {Container} from 'layout/Container'
import {Title} from 'layout/Title'
import React from 'react'
import {DPProductsBlock} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import {ShopRow} from '../shop/ShopRow'
import css from './Products.module.scss'

const styles = fromModule(css)

export const Products: React.FC<DPProductsBlock> = ({title, products}) => {
	const hasProducts = products?.length > 0
	if (!title && !hasProducts) return null

	return (
		<Block type="products" title={title} className={styles.products()}>
			<Container>
				{title && (
					<Title.Marked className={styles.products.title()}>
						{title}
					</Title.Marked>
				)}
				{hasProducts && (
					<div className={styles.products.items()}>
						{products.map(
							(product) =>
								product && (
									<div
										key={product.node_id}
										className={styles.products.items.item()}
									>
										<ShopRow product={product} />
									</div>
								)
						)}
					</div>
				)}
			</Container>
		</Block>
	)
}
