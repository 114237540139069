import {Block} from 'blocks/Block'
import {FormIframe} from 'blocks/content/form/FormIframe'
import {Button} from 'layout/Button'
import {Container} from 'layout/Container'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import {useTranslation} from 'locale/translate'
import {useRouter} from 'next/dist/client/router'
import React from 'react'
import {DPBasketBlock, DPWysiwyg} from 'types/DPTypes'
import {scrollToHash, useShallowLinker} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import {ShopRowContent, ShopRowImage} from '../shop/ShopRow'
import css from './Basket.module.scss'
import {BasketContextProvider, useBasket} from './BasketContext'
import {ProductModel} from './ProductModel'

const styles = fromModule(css)

export const Basket: React.FC<DPBasketBlock> = ({
	title,
	products,
	formassembly_id,
	empty_text
}) => {
	const router = useRouter()
	const step = router.query.step

	return (
		<Block type="basket" id="basket" className={styles.basket()}>
			<BasketContextProvider products={products}>
				<Container>
					{title && (
						<Title.Marked as="h1" className={styles.basket.title()} mod="large">
							{title}
						</Title.Marked>
					)}
					{!step && <BasketItemsStep empty_text={empty_text} />}
					{step === 'checkout' && (
						<BasketCheckoutStep formassembly_id={formassembly_id} />
					)}
					{step === 'confirmed' && <BasketConfirmedStep />}
				</Container>
			</BasketContextProvider>
		</Block>
	)
}

const BasketItemsStep: React.FC<{
	empty_text: DPWysiwyg
}> = ({empty_text}) => {
	const {basket} = useBasket()

	if (!basket.selectedProducts.length) {
		return (
			<div className={styles.items()}>
				<div className={styles.items.empty()}>
					<Wysiwyg>{empty_text}</Wysiwyg>
				</div>
			</div>
		)
	}

	return (
		<div className={styles.items()}>
			{basket.selectedProducts.map((product) => (
				<BasketRow key={product.id} product={product} />
			))}
			<BasketTotal />
		</div>
	)
}

const BasketCheckoutStep: React.FC<{formassembly_id: number}> = ({
	formassembly_id
}) => {
	const {basket, storeBasket} = useBasket()
	const shallowLinker = useShallowLinker()

	return (
		<div className={styles.checkout()}>
			<FormIframe
				formassembly_id={formassembly_id}
				params={basket.params}
				isSubmitted={() => {
					storeBasket(basket.reset())
					shallowLinker({step: 'confirmed'})
				}}
			/>
		</div>
	)
}

const BasketConfirmedStep: React.FC = () => {
	return (
		<div className={styles.confirmed()}>
			<h1>Bedankt voor uw aankoop</h1>
			<p>
				U ontvangt een bevestigingsmail en onze dienst neemt spoedig contact met
				u op voor de verdere opvolging van uw bestelling.
			</p>
		</div>
	)
}

const BasketRow: React.FC<{product: ProductModel}> = ({product}) => {
	const {storeBasket} = useBasket()
	const t = useTranslation().basket

	return (
		<div className={styles.row()}>
			<div className={styles.row.left()}>
				<ShopRowImage url={product.ui.url} image={product.ui.image} />
			</div>
			<div className={styles.row.right()}>
				<ShopRowContent
					url={product.ui.url}
					title={product.ui.title}
					description={product.ui.description}
					className={styles.row.right.content()}
				/>
				<div className={styles.row.right.cart()}>
					<div className={styles.row.right.cart.row()}>
						<div className={styles.row.right.cart.row.amount()}>
							<select
								onChange={(e) =>
									storeBasket(product.setQuantity(parseInt(e.target.value, 10)))
								}
								name="amount"
								id="amount"
							>
								{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((nr) => (
									<option value={nr} key={nr}>
										{nr}
									</option>
								))}
							</select>
						</div>
						{product.ui.selling_price && (
							<p className={styles.row.right.cart.row.price()}>
								<b>{`€ ${product.ui.selling_price
									.toFixed(2)
									.toString()
									.replace(/\./g, ',')}`}</b>
								<span> {t.excl_vat}</span>
							</p>
						)}
					</div>
					<LinkStyled
						className={styles.row.right.cart.delete()}
						mod={['small', 'inherit']}
						onClick={() => storeBasket(product.removeFromBasket())}
					>
						{t.delete}
					</LinkStyled>
				</div>
			</div>
		</div>
	)
}

const BasketTotal: React.FC = () => {
	const {basket} = useBasket()
	const shallowLinker = useShallowLinker()

	const t = useTranslation().basket

	if (basket.isEmpty) return null

	return (
		<div className={styles.total()}>
			<div className={styles.total.block()}>
				<div className={styles.total.block.row()}>
					<Title.Marked>{t.total}</Title.Marked>
					<b className={styles.total.block.total()}>{`€ ${basket.price
						.toFixed(2)
						.toString()
						.replace(/\./g, ',')}`}</b>
				</div>
				<div className={styles.total.block.row()}>
					<small className={styles.total.block.info()}>{t.info}</small>
					<Button
						onClick={() => {
							shallowLinker({step: 'checkout'})
							scrollToHash('#app')
						}}
						className={styles.total.block.button()}
						mod="small"
					>
						{t.submit}
					</Button>
				</div>
			</div>
		</div>
	)
}
