import { Button } from 'layout/Button'
import { CardImage, CardLogo, CardText, CardTitle } from 'layout/cards/Card'
import { switchProgramIcon } from 'nodes/Program'
import React from 'react'
import { DPProgram, DPProject } from 'types/DPTypes'
import { Link } from 'util/link'
import { fromModule } from 'util/styler/Styler'
import css from './ProjectRow.module.scss'

const styles = fromModule(css)

export const ProjectRow: React.FC<{
	project: DPProject
}> = ({project}) => {
	return (
		<div className={styles.projectrow()}>
			<div className={styles.projectrow.content()}>
				<CardTitle as="h3" title={project.title} url={project?.url} />
				<CardText
					text={project.description}
					className={styles.projectrow.content.text()}
					mod="large"
				/>
				<div className={styles.projectrow.content.bottom()}>
					<ProjectRowProgram program={project.program} />
					{project.subscribe_link && (
						<Button to={project.subscribe_link.url} mod="small">
							{project.subscribe_link.title}
						</Button>
					)}
				</div>
			</div>
			{project.image && (
				<CardImage
					image={project.image}
					url={project?.url}
					className={styles.projectrow.image()}
				/>
			)}
			{!project.image && project.logo && (
				<CardLogo
					image={project.logo}
					url={project?.url}
					className={styles.projectrow.image()}
				/>
			)}
		</div>
	)
}

export const ProjectRowProgram: React.FC<{program: DPProgram}> = ({
	program
}) => {
	if (!program) return null
	let colorStyle
	if (program.color?.colorcode) colorStyle = {color: program.color?.colorcode}

	return (
		<Link to={program.url} className={styles.projectrow_program()}>
			<span className={styles.projectrow_program.icon()} style={colorStyle}>
				{switchProgramIcon(program.color?.colorcode)}
			</span>
			{program.title}
		</Link>
	)
}
