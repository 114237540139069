import {Block} from 'blocks/Block'
import {ContactCard} from 'layout/cards/ContactCard'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import React from 'react'
import {DPSfContactsBlock} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import css from './SFContacts.module.scss'

const styles = fromModule(css)

export const SFContacts: React.FC<Omit<DPSfContactsBlock, 'type' | 'id'>> = ({
	title,
	contacts,
	roadmap,
	link
}) => {
	if (!contacts || contacts.length === 0) return null

	return (
		<Block
			type="contacts"
			id="sf-contacts"
			style={
				roadmap &&
				({
					'--color-light': roadmap.program?.color?.colorcode,
					'--color-dark': roadmap.program?.color?.colorcode,
					marginBottom: '120px'
				} as any)
			}
		>
			{title && (
				<Title.Marked className={styles.contacts.title()}>{title}</Title.Marked>
			)}
			<div className={styles.contacts.items()}>
				{contacts.map((contact, i) => (
					<div key={i} className={styles.contacts.items.item()}>
						<ContactCard
							title={contact.name}
							function={contact.sf_functie}
							linkedin={contact.sf_linkedin}
							account={contact.sf_account}
							context_function={contact.context_function}
							context_function_en={contact.context_function_en}
						/>
					</div>
				))}
			</div>
			{link?.url && (
				<div className={styles.contacts.link()}>
					<LinkStyled to={link.url} iconafter="arrow_right">
						{link.title}
					</LinkStyled>
				</div>
			)}
		</Block>
	)
}
