import React, { useEffect, useState } from 'react'
import { facebookLink, linkedInLink, twitterLink } from 'util/share'
import { fromModule } from 'util/styler/Styler'
import { Icon } from './Icon'
import css from './Share.module.scss'
import { Title } from './Title'

const styles = fromModule(css)

export type ShareType = {
	className?: string
	title?: string
}

type SharePlatformType = 'facebook' | 'twitter' | 'linkedin'

export const Share: React.FC<ShareType & {className?: string}> = ({
	className,
	title
}) => {
	return (
		<div className={styles.share.with(className)()}>
			{title && (
				<Title.Marked className={styles.share.title()} mod="normal">
					{title}
				</Title.Marked>
			)}
			<div className={styles.share.items()}>
				<ShareLink platform="facebook" />
				<ShareLink platform="twitter" />
				<ShareLink platform="linkedin" />
			</div>
		</div>
	)
}

const ShareLink: React.FC<{
	platform: SharePlatformType
}> = ({platform}) => {
	const [url, setUrl] = useState(null)
	useEffect(() => {
		setUrl(window.location.href.split(/[?#]/)[0])
	}, [])

	if(!url) return null

	return (
		<a className={styles.link.mod(platform)()} {...sharePlatformProps(platform, url)}>
			<Icon icon={platform} />
		</a>
	)
}

const sharePlatformProps = (platform: SharePlatformType, url: string) => {
	switch (platform) {
		case 'facebook':
			return facebookLink(url)
		case 'twitter':
			return twitterLink('', url)
		case 'linkedin':
			return linkedInLink(url)
	}
}
