import {SidebarBlock} from 'blocks/SidebarBlock'
import {Button} from 'layout/Button'
import {Title} from 'layout/Title'
import React from 'react'
import {DPSidebarCtaBlock} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import css from './SidebarCta.module.scss'

const styles = fromModule(css)

export const SidebarCta: React.FC<Omit<DPSidebarCtaBlock, 'type'>> = ({
	title,
	link
}) => {
	if (!title && !link) return null

	return (
		<SidebarBlock type="sidebar_cta" className={styles.sidebarcta()}>
			<Title.H5 className={styles.sidebarcta.title()}>{title}</Title.H5>
			<Button
				to={link?.url}
				className={styles.sidebarcta.button()}
				mod="maxwidth"
			>
				{link.title}
			</Button>
		</SidebarBlock>
	)
}
