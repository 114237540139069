import {BlockContext} from 'blocks/Block'
import {Container} from 'layout/Container'
import React from 'react'
import {Mod} from 'types/basictypes'
import {fromModule} from 'util/styler/Styler'
import css from './Content.module.scss'

const styles = fromModule(css)

export const Content: React.FC<{className?: string}> = ({
	className,
	children
}) => {
	return (
		<div className={styles.content.with(className)()}>
			<Container>
				<div className={styles.content.row()}>{children}</div>
			</Container>
		</div>
	)
}

export const ContentBlocks: React.FC = ({children}) => {
	return (
		<div className={styles.content_blocks()}>
			<BlockContext.Provider value={{fullwidth: false, block: null}}>
				{children}
			</BlockContext.Provider>
		</div>
	)
}

export const ContentSidebar: React.FC<{
	mod?: Mod<'stickout'>
}> = ({children, mod}) => {
	return <div className={styles.content_sidebar.mod(mod)()}>{children}</div>
}
