import {Block} from 'blocks/Block'
import {Title} from 'layout/Title'
import {Video} from 'layout/Video'
import {Wysiwyg} from 'layout/Wysiwyg'
import React from 'react'
import {DPVideoblockBlock} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import css from './Videoblock.module.scss'

const styles = fromModule(css)

export const Videoblock: React.FC<DPVideoblockBlock> = ({
	title,
	text,
	video_url
}) => {
	if (!video_url) return null

	return (
		<Block type="videoblock" title={title} className={styles.videoblock()}>
			{title && (
				<Title.Marked className={styles.videoblock.title()}>
					{title}
				</Title.Marked>
			)}
			{text && <Wysiwyg className={styles.videoblock.text()}>{text}</Wysiwyg>}
			<div className={styles.videoblock.video()}>
				<Video video_url={video_url} />
			</div>
		</Block>
	)
}
