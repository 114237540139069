import {Carousel, useCarousel} from '@codeurs/carousel'
import {DPStepsBlock, DPStepsItemBlock} from 'types/DPTypes'
import React, {useRef} from 'react'

import {Block} from 'blocks/Block'
import {CarouselType} from 'types/basictypes'
import {Container} from 'layout/Container'
import {Icon} from 'layout/Icon'
import {Image} from 'layout/Image'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {Trusted} from 'layout/Trusted'
import {Video} from 'layout/Video'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Steps.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Steps: React.FC<DPStepsBlock> = ({title, text, items}) => {
	const carousel = useCarousel()
	const carouselRef = useRef(null)

	if (!items || items.length === 0) return null

	return (
		<Block type="steps" title={title} className={styles.steps()}>
			<Container>
				<StepsIntro title={title} text={text} />
				<div className={styles.steps.items()}>
					{items.map((step, i) => (
						<a
							key={i}
							onClick={() => {
								carousel.goTo(i)

								const el = carouselRef.current
								if (!el) return
								const y =
									el.getBoundingClientRect().top + window.pageYOffset - 300
								setTimeout(
									() => window.scrollTo({top: y, behavior: 'smooth'}),
									0
								)
							}}
							className={styles.steps.items.item.is({
								active: carousel.current === i
							})()}
						>
							<div
								className={styles.steps.items.item.image.mod({
									contain: step?.image_contain
								})()}
							>
								{step.image?.src && !step?.image_contain && (
									<Image
										{...step.image}
										format="landscape_thumbnail"
										layout="responsive"
									/>
								)}
								{step.image?.src && step?.image_contain && (
									<Image
										{...step.image}
										layout="fill"
										objectFit="contain"
										objectPosition="center center"
										className={styles.steps.items.item.image.bg()}
									/>
								)}
								<span className={styles.steps.items.item.image.overlay()} />
							</div>
							{step.title && (
								<h5 className={styles.steps.items.item.title()}>
									{step.title}
								</h5>
							)}
						</a>
					))}
				</div>
			</Container>
			<div ref={carouselRef}>
				<StepsCarousel carousel={carousel} items={items} />
			</div>
		</Block>
	)
}

const StepsIntro: React.FC<{title?: string; text?: string}> = ({
	title,
	text
}) => {
	if (!title && !text) return null

	return (
		<div className={styles.intro()}>
			{title && (
				<Title.Marked className={styles.intro.title()}>{title}</Title.Marked>
			)}
			{text && <Wysiwyg className={styles.intro.text()}>{text}</Wysiwyg>}
		</div>
	)
}

const StepsCarousel: React.FC<{
	carousel: CarouselType
	items: Array<DPStepsItemBlock>
}> = ({carousel, items}) => {
	return (
		<div id="stepscarousel" className={styles.carousel()}>
			<Container>
				<div className={styles.carousel.slides()}>
					<Carousel full {...carousel}>
						{items.map((step, i) => (
							<div
								key={i}
								className={styles.carousel.slides.slide.is({
									active: carousel.current === i
								})()}
							>
								<StepsSlide {...step} />
							</div>
						))}
					</Carousel>
					<StepsCarouselControls carousel={carousel} />
				</div>
			</Container>
		</div>
	)
}

const StepsSlide: React.FC<DPStepsItemBlock> = ({
	title,
	text,
	link,
	image,
	video_url
}) => {
	return (
		<div className={styles.slide()}>
			<div className={styles.slide.row()}>
				<div
					className={styles.slide.content.mod({
						fullwidth: !image?.src && !video_url
					})()}
				>
					{title && (
						<Title.H3 as="h2" className={styles.slide.content.title()}>
							{title}
						</Title.H3>
					)}
					{text && (
						<div className={styles.slide.content.text()}>
							<Trusted>{text}</Trusted>
						</div>
					)}
					{link?.url && (
						<LinkStyled to={link.url} className={styles.slide.content.link()}>
							{link.title}
						</LinkStyled>
					)}
				</div>
				{(image?.src || video_url) && (
					<div className={styles.slide.media()}>
						{!video_url && (
							<div style={{maxWidth: image?.width, margin: '0 auto'}}>
								<Image
									{...image}
									className={styles.slide.media.img()}
									sizes={'(max-width: 800px) 100vw, 1200px'}
								/>
							</div>
						)}
						{video_url && <Video video_url={video_url} />}
					</div>
				)}
			</div>
		</div>
	)
}

const StepsCarouselControls: React.FC<{
	carousel: CarouselType
}> = ({carousel}) => {
	return (
		<div className={styles.carouselcontrols()}>
			<a
				onClick={() => carousel.goPrevious()}
				className={styles.carouselcontrols.prev.is({
					disabled: !carousel.hasPrevious()
				})()}
			>
				<Icon icon="chevron_right_carousel" />
			</a>
			<a
				onClick={() => carousel.goNext()}
				className={styles.carouselcontrols.next.is({
					disabled: !carousel.hasNext()
				})()}
			>
				<Icon icon="chevron_right_carousel" />
			</a>
		</div>
	)
}
