import {Block} from 'blocks/Block'
import {ContactCard} from 'layout/cards/ContactCard'
import {Title} from 'layout/Title'
import React from 'react'
import {DPSfBoardBlock} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import css from './SFBoard.module.scss'

const styles = fromModule(css)

export const SFBoard: React.FC<DPSfBoardBlock> = ({title, contacts}) => {
	if (contacts?.length === 0) return null

	// https://3.basecamp.com/3274428/buckets/19598874/todos/3956129330
	const topContextFunctions = [
		'Voorzitter Raad van Bestuur',
		'Penningmeester Raad van Bestuur',
		'Ondervoorzitter Raad van Bestuur'
	]
	const topContacts = []
	topContextFunctions.forEach((contextFunction) => {
		const foundTopContact = contacts.find(
			(contact) => contact.context_function === contextFunction
		)
		if (foundTopContact) topContacts.push(foundTopContact)
	})
	const rest = contacts
		.filter(
			(contact) => !new Set(topContextFunctions).has(contact.context_function)
		)
		.sort((a, b) => a.name.localeCompare(b.name))

	return (
		<Block type="sf_board" id="sf-contacts">
			{title && (
				<Title.Marked className={styles.sfboard.title()}>{title}</Title.Marked>
			)}
			<div className={styles.sfboard.items()}>
				{topContacts.map((contact, i) => (
					<div key={i} className={styles.sfboard.items.item()}>
						<ContactCard
							title={contact.name}
							function={contact.sf_functie}
							linkedin={contact.sf_linkedin}
							account={contact.sf_account}
							context_function={contact.context_function}
							context_function_en={contact.context_function_en}
						/>
					</div>
				))}
			</div>
			<div className={styles.sfboard.items()}>
				{rest.map((contact, i) => (
					<div key={i} className={styles.sfboard.items.item()}>
						<ContactCard
							title={contact.name}
							function={contact.sf_functie}
							linkedin={contact.sf_linkedin}
							account={contact.sf_account}
							context_function={contact.context_function}
							context_function_en={contact.context_function_en}
						/>
					</div>
				))}
			</div>
		</Block>
	)
}
