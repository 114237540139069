import {Content, ContentBlocks, ContentSidebar} from 'layout/content/Content'
import {
	DPConcept,
	DPDate,
	DPProgram,
	DPProjectNode,
	DPRoadmap
} from 'types/DPTypes'
import {Hero, HeroBack, HeroFinanciers, HeroTitle} from 'layout/Hero'
import {Paragraphs, splitContent} from 'blocks/Block'
import React, {useContext} from 'react'
import {SidebarBlock, SidebarParagraph} from 'blocks/SidebarBlock'

import {Blocks} from 'layout/content/Blocks'
import {Button} from 'layout/Button'
import {GeneralContext} from 'types/general'
import Image from 'next/image'
import {Intro} from 'layout/Intro'
import {LinkStyled} from 'layout/LinkStyled'
import {Share} from 'layout/Share'
import css from './Project.module.scss'
import {dpDateToJsDate} from 'util/dates'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'locale/translate'

const styles = fromModule(css)

export const Project: React.FC<DPProjectNode> = ({
	title,
	image,
	description,
	program,
	categories,
	financiers_label,
	financiers_banners,
	logo,
	sidebar,
	subscribe_link,
	project_type,
	date,
	to_date,
	blocks
}) => {
	const {links} = useContext(GeneralContext)
	const t = useTranslation()
	const {contentBlocks, fullBlocks} = splitContent(blocks)
	const hasFinanciersBanners =
		financiers_banners && financiers_banners.length > 0

	return (
		<div>
			<Hero className={styles.project.hero()}>
				<div className={styles.project.hero.content()}>
					<div className={styles.project.hero.content.left()}>
						<HeroBack url={links.projects}>{t.project.show_all}</HeroBack>
						<HeroTitle>{title}</HeroTitle>
						<Breadcrumbs program={program} categories={categories} />
					</div>
					<div className={styles.project.hero.content.right()}>
						<HeroFinanciers
							label={financiers_label}
							banners={financiers_banners}
						/>
					</div>
				</div>
			</Hero>
			<Content>
				<ContentBlocks>
					<Intro image={image} description={description} reverse={true} />
					<Paragraphs blocks={contentBlocks} />
				</ContentBlocks>
				<ContentSidebar mod={logo && !hasFinanciersBanners ? 'stickout' : null}>
					{logo && (
						<div className={styles.project.logo()}>
							<div className={styles.project.logo.img()}>
								<Image priority {...logo} />
							</div>
						</div>
					)}
					{subscribe_link && (
						<div className={styles.project.subscribe()}>
							<Button to={subscribe_link.url}>{subscribe_link.title}</Button>
						</div>
					)}
					{sidebar.map((bl, i) => (
						<SidebarParagraph {...bl} key={i} />
					))}
					<SidebarBlock type="custom">
						<Share title={t.project.share} />
					</SidebarBlock>
					<ProjectInfo
						project_type={project_type}
						date={date}
						to_date={to_date}
					/>
				</ContentSidebar>
			</Content>
			<Blocks>
				<Paragraphs blocks={fullBlocks} />
			</Blocks>
		</div>
	)
}

const ProjectInfo: React.FC<{
	project_type: string
	date?: DPDate
	to_date?: DPDate
}> = ({project_type, date, to_date}) => {
	const t = useTranslation()
	const jsFromDate = dpDateToJsDate(date)
	const jsToDate = dpDateToJsDate(to_date)

	if (!project_type && !date) return null

	return (
		<div className={styles.info()}>
			{project_type && (
				<div className={styles.info.item()}>
					{t.project.type}: {project_type}
				</div>
			)}
			{jsFromDate && jsToDate && (
				<div className={styles.info.item()}>
					{t.months[jsFromDate.getMonth()]} {jsFromDate.getFullYear()} -{' '}
					{t.months[jsToDate.getMonth()]} {jsToDate.getFullYear()}
				</div>
			)}
		</div>
	)
}

export const Breadcrumbs: React.FC<{
	program?: DPProgram
	categories: (DPConcept | DPRoadmap)[]
}> = ({program, categories}) => {
	if (!program?.url && (!categories || categories.length === 0)) return null
	const hasCategories = categories && categories.length !== 0

	if (hasCategories) {
		const category = categories[0]
		const isConcept = category.type === 'concept'
		const isRoadmap = category.type === 'roadmap'

		if (isConcept) {
			const categoryConcept = category as DPConcept

			return (
				<div className={styles.breadcrumbs()}>
					{categoryConcept.roadmap?.program?.url && (
						<LinkStyled
							to={categoryConcept.roadmap.program.url}
							className={styles.breadcrumbs.item()}
						>
							{categoryConcept.roadmap.program.title}
						</LinkStyled>
					)}
					{categoryConcept.roadmap?.url && (
						<LinkStyled
							to={categoryConcept.roadmap.url}
							className={styles.breadcrumbs.item()}
						>
							{categoryConcept.roadmap.title}
						</LinkStyled>
					)}
					<LinkStyled
						to={categoryConcept.url}
						className={styles.breadcrumbs.item()}
					>
						{categoryConcept.title}
					</LinkStyled>
				</div>
			)
		}

		if (isRoadmap) {
			const categoryRoadmap = category as DPRoadmap

			return (
				<div className={styles.breadcrumbs()}>
					<LinkStyled
						to={categoryRoadmap.program.url}
						className={styles.breadcrumbs.item()}
					>
						{categoryRoadmap.program.title}
					</LinkStyled>
					<LinkStyled
						to={categoryRoadmap.url}
						className={styles.breadcrumbs.item()}
					>
						{categoryRoadmap.title}
					</LinkStyled>
				</div>
			)
		}
	}

	if (program?.url && !hasCategories) {
		return (
			<div className={styles.breadcrumbs()}>
				<LinkStyled to={program.url} className={styles.breadcrumbs.item()}>
					{program.title}
				</LinkStyled>
			</div>
		)
	}
}
