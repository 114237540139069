import {DPHomeBlock, DPImage} from 'types/DPTypes'

import {Block} from 'blocks/Block'
import {Button} from 'layout/Button'
import {Container} from 'layout/Container'
import Image from 'next/image'
import React from 'react'
import {Targets} from './Targets'
import {Title} from 'layout/Title'
import {Trusted} from 'layout/Trusted'
import css from './Home.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Home: React.FC<DPHomeBlock> = ({
	background,
	title,
	description,
	links,
	targets,
	collaboration_label,
	collaboration_image
}) => {
	return (
		<Block type="home" title={title} className={styles.home()}>
			<div className={styles.home.top()}>
				{background && (
					<Image
						src={background.src}
						alt={background?.alt}
						layout="fill"
						objectFit="cover"
						priority
						sizes="100vw"
					/>
				)}
				<div className={styles.home.top.gradient()} />
				<div className={styles.home.top.content()}>
					<Container>
						<div className={styles.home.top.content.row()}>
							<div className={styles.home.top.content.left()}>
								<Title.H2
									as="h1"
									className={styles.home.top.content.left.title()}
								>
									{title}
								</Title.H2>
							</div>
							<div className={styles.home.top.content.right()}>
								<HomeCollaboration
									label={collaboration_label}
									image={collaboration_image}
								/>
							</div>
						</div>
					</Container>
				</div>
			</div>
			<div className={styles.home.bottom()}>
				<Container>
					<div className={styles.home.bottom.row()}>
						<div className={styles.home.bottom.left()}>
							<div className={styles.home.bottom.left.description()}>
								<Trusted>{description}</Trusted>
							</div>
							{links?.length > 0 && (
								<div className={styles.home.bottom.left.buttons()}>
									{links.map((btn) => (
										<Button
											to={btn.url}
											mod={['large', 'outline', 'bgdark']}
											iconafter="arrow_right_large"
											className={styles.home.bottom.left.buttons.button()}
											key={btn.url}
										>
											{btn.title}
										</Button>
									))}
								</div>
							)}
						</div>
						<div className={styles.home.bottom.right()}>
							<div className={styles.home.bottom.right.targets()}>
								<Targets targets={targets} />
							</div>
						</div>
					</div>
				</Container>
			</div>
		</Block>
	)
}

const HomeCollaboration: React.FC<{
	label: string
	image: DPImage
}> = ({label, image}) => {
	return (
		<div className={styles.collaboration()}>
			<p className={styles.collaboration.label()}>{label}</p>
			<div className={styles.collaboration.image()}>
				<Image priority {...image} />
			</div>
		</div>
	)
}
