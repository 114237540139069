import {DPImage, DPSfAccountTerm, DPUrl} from 'types/DPTypes'
import {useContext, useEffect, useState} from 'react'

import {GeneralContext} from 'types/general'
import {Image} from 'layout/Image'
import {Link} from 'util/link'
import {LinkStyled} from 'layout/LinkStyled'
import {SocialLink} from 'layout/Social'
import css from './ContactCard.module.scss'
import {deobfuscate} from 'util/obfuscate'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'locale/translate'

const styles = fromModule(css)

export const ContactCard: React.FC<{
	title?: string
	image?: DPImage
	url?: DPUrl
	function?: string
	account?: DPSfAccountTerm
	email?: string
	linkedin?: DPUrl
	context_function?: string
	context_function_en?: string
}> = (contact) => {
	const [contactEmail, setContactEmail] = useState(null)
	const lang = useContext(GeneralContext).lang
	const t = useTranslation()

	useEffect(() => {
		if (contact) setContactEmail(deobfuscate(contact?.email))
	}, [contact])

	if (!contact) return null

	return (
		<div className={styles.contactcard()}>
			{contact.image && (
				<Link to={contact.url} className={styles.contactcard.avatar()}>
					<Image {...contact.image} format="avatar" />
				</Link>
			)}
			<h5 className={styles.contactcard.fullname()}>
				<Link to={contact.url}>{contact.title}</Link>
			</h5>
			{contact.context_function && (
				<h6 className={styles.contactcard.function.mod('context')()}>
					{lang === 'en' && !!contact.context_function_en
						? contact.context_function_en
						: contact.context_function}
				</h6>
			)}
			{(contact.function || '').split(';').map((piece, i) => (
				<h6 key={i} className={styles.contactcard.function()}>
					{piece}
				</h6>
			))}
			{contact.account && (
				<LinkStyled
					to={contact.account.sf_website}
					target="_blank"
					className={styles.contactcard.account()}
				>
					{contact.account.name}
				</LinkStyled>
			)}
			{contact.email && contactEmail && (
				<LinkStyled
					to={`mailto:${contactEmail}`}
					target="_blank"
					className={styles.contactcard.email()}
				>
					{t.contact.send_mail}
				</LinkStyled>
			)}
			{contact.linkedin && (
				<div className={styles.contactcard.social()}>
					<SocialLink type="linkedin" url={contact.linkedin} mod="round" />
				</div>
			)}
		</div>
	)
}
