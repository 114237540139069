import {Content, ContentBlocks, ContentSidebar} from 'layout/content/Content'
import {DPArticleNode, DPSfContactTerm} from 'types/DPTypes'
import {Hero, HeroBack, HeroTitle} from 'layout/Hero'
import {Paragraphs, splitContent} from 'blocks/Block'
import {SidebarBlock, SidebarParagraph} from 'blocks/SidebarBlock'

import {Blocks} from 'layout/content/Blocks'
import {ContactCard} from 'layout/cards/ContactCard'
import Image from 'next/image'
import {Intro} from 'layout/Intro'
import React from 'react'
import {Share} from 'layout/Share'
import {Title} from 'layout/Title'
import css from './Article.module.scss'
import {dpDateToJsDate} from 'util/dates'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'locale/translate'

const styles = fromModule(css)

export const Article: React.FC<DPArticleNode> = ({
	title,
	article_type,
	date,
	description,
	blocks,
	sidebar,
	internal_author,
	external_author,
	image
}) => {
	const t = useTranslation()
	const jsDate = dpDateToJsDate(date)
	const day = jsDate?.getDate()
	const month = jsDate && t.months[jsDate.getMonth()]
	const year = jsDate?.getFullYear()

	const {contentBlocks, fullBlocks} = splitContent(blocks)
	const hasContent =
		description || contentBlocks.length > 0 || sidebar.length > 0

	const imgPortrait = image?.height > image?.width
	const imageProps = imgPortrait
		? {
				src: image.src,
				alt: image?.alt,
				layout: 'fill' as const,
				objectFit: 'contain' as const
		  }
		: {...image}

	return (
		<div className={styles.article()}>
			<Hero>
				{article_type?.overview_page?.url && (
					<HeroBack url={article_type.overview_page?.url}>
						{article_type.overview_page?.title}
					</HeroBack>
				)}
				<div className={styles.article.hero()}>
					<div className={styles.article.hero.content()}>
						<HeroTitle>{title}</HeroTitle>
						{date && (
							<p className={styles.article.hero.content.date()}>
								{`${t.article.published_on} ${
									day <= 9 ? `0${day}` : day
								} ${month} ${year}`}
							</p>
						)}
					</div>
					{image && (
						<div className={styles.article.hero.image()}>
							<div
								className={styles.article.hero.image.wrapper.mod({
									portrait: imgPortrait
								})()}
							>
								<Image
									priority
									{...imageProps}
									sizes="(max-width: 1024px) 100vw, (max-width: 1920px) 50vw, 1070px"
								/>
							</div>
						</div>
					)}
				</div>
			</Hero>
			{hasContent && (
				<Content className={styles.article.content()}>
					<ContentBlocks>
						<Intro description={description} />
						<Paragraphs blocks={contentBlocks} />
					</ContentBlocks>
					<ContentSidebar>
						{sidebar.map((bl, i) => (
							<SidebarParagraph {...bl} key={i} />
						))}
						{internal_author && (
							<SidebarParagraph
								type="sidebar_contact"
								id={null}
								title={t.article.author}
								contact={[internal_author]}
							/>
						)}
						{external_author && (
							<SidebarContact
								title={t.article.author}
								contact={external_author}
							/>
						)}
						<SidebarBlock type="custom">
							<Share title={t.article.share} />
						</SidebarBlock>
					</ContentSidebar>
				</Content>
			)}
			<Blocks>
				<Paragraphs blocks={fullBlocks} />
			</Blocks>
		</div>
	)
}

export const SidebarContact: React.FC<{
	title: string
	contact: DPSfContactTerm
}> = ({title, contact}) => {
	const t = useTranslation()
	if (!contact) return null

	return (
		<SidebarBlock type="sidebar_contact" className={styles.author()}>
			<Title.Marked className={styles.author.title()}>
				{title || t.contact.more_info}
			</Title.Marked>
			<ContactCard title={contact.name} />
		</SidebarBlock>
	)
}
