import {Paragraphs, splitContent} from 'blocks/Block'
import {Titleblock} from 'blocks/hero/titleblock/Titleblock'
import {SidebarParagraph} from 'blocks/SidebarBlock'
import {Blocks} from 'layout/content/Blocks'
import {Content, ContentBlocks, ContentSidebar} from 'layout/content/Content'
import {Intro} from 'layout/Intro'
import React from 'react'
import {DPPageNode, DPTitleblockBlock} from 'types/DPTypes'

export const Page: React.FC<Omit<DPPageNode, 'type'>> = ({blocks, sidebar}) => {
	const {titleBlock, contentBlocks, fullBlocks} = splitContent(blocks)
	const hasContent = contentBlocks.length > 0 || sidebar.length > 0

	const hasTitleBlockImage = titleBlock?.image?.src
	const modifiedTitleBlock: DPTitleblockBlock = titleBlock && {
		...titleBlock,
		description: hasTitleBlockImage ? titleBlock.description : null
	}

	let hasStickout = false
	if (!hasTitleBlockImage && sidebar.length > 0) {
		if (sidebar[0].type === 'sidebar_cta') hasStickout = true
		if (
			sidebar[0].type === 'sidebar_sf_contact' &&
			sidebar[0].cta_label &&
			sidebar[0].contact
		)
			hasStickout = true
	}
	const sidebarStickout = hasStickout ? 'stickout' : null

	return (
		<div>
			{titleBlock && (
				<Titleblock {...modifiedTitleBlock} stickout={hasStickout} />
			)}
			{hasContent && (
				<Content>
					<ContentBlocks>
						{!hasTitleBlockImage && titleBlock?.description && (
							<Intro description={titleBlock.description} />
						)}
						<Paragraphs blocks={contentBlocks} />
					</ContentBlocks>
					<ContentSidebar mod={sidebarStickout}>
						{sidebar.map((bl, i) => (
							<SidebarParagraph {...bl} key={i} />
						))}
					</ContentSidebar>
				</Content>
			)}
			<Blocks>
				<Paragraphs blocks={fullBlocks} />
			</Blocks>
		</div>
	)
}
