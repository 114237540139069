import {SidebarBlock} from 'blocks/SidebarBlock'
import {ContactCard} from 'layout/cards/ContactCard'
import {Title} from 'layout/Title'
import {useTranslation} from 'locale/translate'
import React from 'react'
import {DPSidebarContactBlock} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import css from './SidebarContact.module.scss'

const styles = fromModule(css)

export const SidebarContact: React.FC<
	Omit<DPSidebarContactBlock, 'type' | 'id'>
> = ({title, contact: contacts}) => {
	const t = useTranslation()
	if (!contacts?.length) return null

	return (
		<SidebarBlock type="sidebar_contact" className={styles.sidebarcontact()}>
			<Title.Marked className={styles.sidebarcontact.title()} mod="normal">
				{title || t.contact.more_info}
			</Title.Marked>
			{contacts.map((contact) => (
				<ContactCard key={contact.url} {...contact} />
			))}
		</SidebarBlock>
	)
}
