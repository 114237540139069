import {Icon} from 'layout/Icon'
import {Trusted} from 'layout/Trusted'
import {useState} from 'react'
import AnimateHeight from 'react-animate-height'
import {DPHomeTargetBlock} from 'types/DPTypes'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import css from './Targets.module.scss'

const styles = fromModule(css)

export const Targets: React.FC<{
	targets: DPHomeTargetBlock[]
}> = ({targets}) => {
	const [activeID, setOpenID] = useState<number | null>(targets[0].id)

	return (
		<nav className={styles.targets()}>
			{targets.map((target) => (
				<TargetsItem
					open={activeID === target.id}
					onOpen={() => setOpenID(target.id)}
					target={target}
					key={target.id}
				>
					{target.label}
				</TargetsItem>
			))}
		</nav>
	)
}

export const TargetsItem: React.FC<{
	target: DPHomeTargetBlock
	open: boolean
	onOpen: () => void
}> = ({target, open, onOpen}) => {
	const color = target.color?.colorcode
	const TitleTag = open && target.link?.url ? Link : 'span'
	const titleProps = open && target.link?.url ? {to: target.link?.url} : {}

	return (
		<div className={styles.item.is({open})()} onClick={onOpen}>
			<div
				className={styles.item.title()}
				onClick={onOpen}
				{...(open && color && {style: {color}})}
			>
				<TitleTag {...titleProps} className={styles.item.title.label()}>
					{target.label}
				</TitleTag>
				{!open && (
					<span className={styles.item.title.icon()}>
						<Icon icon="chevron_right" />
					</span>
				)}
			</div>
			<AnimateHeight height={open ? 'auto' : 0}>
				<div className={styles.item.info()} onClick={onOpen}>
					<div className={styles.item.info.description()}>
						<Trusted>{target.description}</Trusted>
					</div>
					{target.link?.url && (
						<div className={styles.item.info.cta()}>
							<Link
								className={styles.item.info.cta.link()}
								to={target.link?.url}
								{...(color && {style: {color}})}
							>
								<span className={styles.item.info.cta.link.text()}>
									{target.link?.title}
								</span>
								<span className={styles.item.info.cta.link.icon()}>
									<Icon icon="arrow_right_large" />
								</span>
							</Link>
						</div>
					)}
				</div>
			</AnimateHeight>
		</div>
	)
}
