import {Block, Paragraphs, splitContent as splitContent} from 'blocks/Block'
import {SidebarBlock, SidebarParagraph} from 'blocks/SidebarBlock'
import {Button} from 'layout/Button'
import {EventcardDate} from 'layout/cards/Eventcard'
import {Blocks} from 'layout/content/Blocks'
import {Content, ContentBlocks, ContentSidebar} from 'layout/content/Content'
import {Hero, HeroBack, HeroTitle} from 'layout/Hero'
import {Icon} from 'layout/Icon'
import {Intro} from 'layout/Intro'
import {Share} from 'layout/Share'
import {Title} from 'layout/Title'
import {Trusted} from 'layout/Trusted'
import {Wysiwyg} from 'layout/Wysiwyg'
import {useTranslation} from 'locale/translate'
import React, {useContext, useState} from 'react'
import AnimateHeight from 'react-animate-height'
import {DPEventNode, DPScheduleItemBlock, DPWysiwyg} from 'types/DPTypes'
import {GeneralContext} from 'types/general'
import {dpDateToJsDate} from 'util/dates'
import {fromModule} from 'util/styler/Styler'
import css from './Event.module.scss'

const styles = fromModule(css)

export const Event: React.FC<DPEventNode> = (page) => {
	const {
		title,
		image,
		description,
		branding,
		date,
		to_date,
		subscribe_link,
		price,
		sidebar,
		blocks
	} = page
	const {links} = useContext(GeneralContext)
	const t = useTranslation()
	const {contentBlocks, fullBlocks} = splitContent(blocks)

	return (
		<div>
			<Hero>
				<HeroBack url={links.calendar}>{t.events.show_all}</HeroBack>
				<div className={styles.event.hero()}>
					<div className={styles.event.hero.date()}>
						{branding?.icon && (
							<div className={styles.event.hero.date.branding()}>
								<img src={branding.icon?.src} alt={t.event.branding.icon} />
							</div>
						)}
						<EventcardDate date={date} to_date={to_date} mod="large" />
					</div>
					<HeroTitle>{title}</HeroTitle>
				</div>
			</Hero>
			<Content>
				<ContentBlocks>
					<Intro image={image} description={description} />
					<Paragraphs blocks={contentBlocks} />
					{!blocks.find((bl) => bl.type === 'details') && (
						<EventDetails {...page} />
					)}
				</ContentBlocks>
				<ContentSidebar>
					{subscribe_link && (
						<div className={styles.event.subscribe()}>
							<Button to={subscribe_link.url} mod="maxwidth">
								{subscribe_link.title || t.event.subscribe}
							</Button>
							{price?.name && (
								<p className={styles.event.subscribe.price()}>
									{price?.label || price.name}
								</p>
							)}
						</div>
					)}
					{sidebar.map((bl, i) => (
						<SidebarParagraph {...bl} key={i} />
					))}
					<SidebarBlock type="custom">
						<Share title={t.event.share} />
					</SidebarBlock>
				</ContentSidebar>
			</Content>
			<Blocks>
				<Paragraphs blocks={fullBlocks} />
			</Blocks>
		</div>
	)
}

export const EventDetails: React.FC<DPEventNode> = (event) => {
	const t = useTranslation()

	return (
		<Block type="details" id="details">
			<EventToggleContent title={t.event.practicals.title}>
				<EventPracticals {...event} />
			</EventToggleContent>
			{event.schedule.length > 0 && (
				<EventToggleContent title={t.event.program}>
					<EventSchedule {...event} />
				</EventToggleContent>
			)}
		</Block>
	)
}

const EventToggleContent: React.FC<{title: string}> = ({title, children}) => {
	const [isOpen, setOpen] = useState(true)
	if (!title || !children) return null

	return (
		<div className={styles.togglecontent()}>
			<a
				onClick={() => setOpen(!isOpen)}
				className={styles.togglecontent.toggle()}
			>
				<Title.Marked>{title}</Title.Marked>
				<span
					className={styles.togglecontent.toggle.icon.is({
						open: isOpen
					})()}
				>
					<Icon icon="chevron_down" />
				</span>
			</a>
			<AnimateHeight height={isOpen ? 'auto' : 0}>
				<div className={styles.togglecontent.content()}>{children}</div>
			</AnimateHeight>
		</div>
	)
}

const EventPracticals: React.FC<DPEventNode> = ({
	date,
	to_date,
	address,
	price_info,
	location,
	language,
	organisor,
	subscribe_link
}) => {
	const t = useTranslation()

	if (!date) return null

	const jsFromDate = dpDateToJsDate(date)
	const jsToDate = dpDateToJsDate(to_date)
	const parseDate = (date: Date) => {
		const day = date.getDate()
		return `${day <= 9 ? `0${day}` : day} ${
			t.months[date.getMonth()]
		} ${date.getFullYear()}`
	}
	const parseTime = (date: Date) => {
		const hours = date.getHours()
		const mins = date.getUTCMinutes()
		return `${hours < 10 ? '0' + hours : hours}:${
			mins < 10 ? '0' + mins : mins
		}`
	}
	const sameDay = (d1, d2) => {
		return (
			d1.getFullYear() === d2.getFullYear() &&
			d1.getMonth() === d2.getMonth() &&
			d1.getDate() === d2.getDate()
		)
	}

	let dateString = ''
	if (jsFromDate && !jsToDate) {
		dateString = `${parseDate(jsFromDate)} ${t.dates.at} ${parseTime(
			jsFromDate
		)}`
	}
	if (jsFromDate && jsToDate && sameDay(jsFromDate, jsToDate)) {
		dateString = `${parseDate(jsFromDate)} ${t.dates.from} ${parseTime(
			jsFromDate
		)} ${t.dates.to} ${parseTime(jsToDate)}`
	}
	if (jsFromDate && jsToDate && !sameDay(jsFromDate, jsToDate)) {
		dateString = `
			${parseDate(jsFromDate)} ${parseTime(jsFromDate)} ${t.dates.to}
			${parseDate(jsToDate)} ${parseTime(jsToDate)}
		`
	}

	return (
		<div className={styles.practicals()}>
			<div className={styles.practicals.content()}>
				<EventPracticalsItem
					label={t.event.practicals.when}
					data={dateString}
				/>
				{address && (
					<EventPracticalsItem
						label={t.event.practicals.where}
						data={address}
					/>
				)}
				{!address && location && (
					<EventPracticalsItem
						label={t.event.practicals.where}
						data={location}
					/>
				)}
				{price_info && (
					<EventPracticalsItem
						label={t.event.practicals.budget}
						data={price_info}
					/>
				)}
				{language && (
					<EventPracticalsItem
						label={t.event.practicals.language}
						data={language.name}
					/>
				)}
				{organisor && (
					<EventPracticalsItem
						label={t.event.practicals.organisor}
						data={organisor}
					/>
				)}
			</div>
			{subscribe_link && (
				<Button to={subscribe_link.url} className={styles.practicals.button()}>
					{subscribe_link.title || t.event.subscribe}
				</Button>
			)}
		</div>
	)
}

const EventPracticalsItem: React.FC<{
	label: string
	data: DPWysiwyg
}> = ({label, data}) => {
	if (!label && !data) return null

	return (
		<div className={styles.practicals_item()}>
			<p className={styles.practicals_item.label()}>{label}</p>
			<Wysiwyg className={styles.practicals_item.data()}>{data}</Wysiwyg>
		</div>
	)
}

const EventSchedule: React.FC<DPEventNode> = ({
	schedule_description,
	schedule
}) => {
	return (
		<div className={styles.schedule()}>
			{schedule_description && (
				<div className={styles.schedule.text()}>
					<Trusted>{schedule_description}</Trusted>
				</div>
			)}
			{schedule.map((item) => (
				<EventScheduleItem {...item} key={item.id} />
			))}
		</div>
	)
}

const EventScheduleItem: React.FC<DPScheduleItemBlock> = ({
	title,
	description,
	time,
	is_break
}) => {
	if (!time && !title) return null

	return (
		<div className={styles.schedule_item.mod({break: is_break})()}>
			<p className={styles.schedule_item.hour()}>{time}</p>
			<div className={styles.schedule_item.block()}>
				<h5 className={styles.schedule_item.block.title()}>{title}</h5>
				<div className={styles.schedule_item.block.description()}>
					<Trusted>{description}</Trusted>
				</div>
			</div>
		</div>
	)
}
