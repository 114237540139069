import {
	UseNumericMenuProps,
	useInstantSearch,
	useNumericMenu
} from 'react-instantsearch-hooks-web'

import {IndexUiState} from 'instantsearch.js/es/types'

/**
 * Add algolia autocomplete stylesheet to your page:
 * <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@algolia/autocomplete-theme-classic" />
 */

const NumericMenu: React.FC<
	UseNumericMenuProps & {
		all?: string
		resetState?: (s: IndexUiState) => IndexUiState
	}
> = ({all, resetState, ...props}) => {
	const {items, refine} = useNumericMenu(props)
	const {setIndexUiState} = useInstantSearch()
	const showAll = !items.find((i) => i.isRefined)

	return (
		<div className="ais-NumericMenu">
			<ul className="ais-NumericMenu-list">
				{items.map((item) => (
					<li
						key={item.value}
						className={`ais-NumericMenu-item ${
							item.isRefined ? ' ais-NumericMenu-item--selected' : ''
						}`}
					>
						<label className="ais-NumericMenu-label">
							<input
								className="ais-NumericMenu-radio"
								type="radio"
								checked={item.isRefined}
								onChange={() => refine(item.value)}
							/>
							<span className="ais-NumericMenu-labelText">{item.label}</span>
						</label>
					</li>
				))}
				<li
					className={`ais-NumericMenu-item ${
						showAll ? 'ais-NumericMenu-item--selected' : ''
					}`}
				>
					<label className="ais-NumericMenu-label">
						<input
							className="ais-NumericMenu-radio"
							type="radio"
							checked={showAll}
							onChange={() => {
								setIndexUiState((s) => {
									if (resetState) return resetState(s)
									const {numericMenu, ...rest} = s
									return rest
								})
							}}
						/>
						<span className="ais-NumericMenu-labelText">
							{all ? all : 'All'}
						</span>
					</label>
				</li>
			</ul>
		</div>
	)
}

export default NumericMenu
