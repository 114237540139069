import {HierarchicalMenu, RefinementList} from 'react-instantsearch-hooks-web'
import {
	Overview,
	OverviewBottom,
	OverviewContent,
	OverviewFilters,
	OverviewResults,
	OverviewSearchLabel,
	OverviewTab,
	OverviewTop
} from 'layout/overview/Overview'
import React, {useState} from 'react'

import {Autocomplete} from 'util/autocomplete'
import {Block} from 'blocks/Block'
import {Container} from 'layout/Container'
import {DPFullcalendarBlock} from 'types/DPTypes'
import {IndexUiState} from 'instantsearch.js/es/types'
import NumericMenu from 'layout/overview/NumericMenu'
import {Title} from 'layout/Title'
import addMonths from 'date-fns/addMonths'
import css from './FullCalendar.module.scss'
import endOfMonth from 'date-fns/endOfMonth'
import endOfWeek from 'date-fns/endOfWeek'
import {fromModule} from 'util/styler/Styler'
import getUnixTime from 'date-fns/getUnixTime'
import startOfMonth from 'date-fns/startOfMonth'
import startOfWeek from 'date-fns/startOfWeek'
import {useRouter} from 'next/dist/client/router'
import {useTranslation} from 'locale/translate'

const styles = fromModule(css)

export const FullCalendar: React.FC<
	DPFullcalendarBlock & {
		_initial: {
			flandersfood: any
			any: any
		}
	}
> = ({title, _initial}) => {
	const [today] = useState(new Date())
	const t = useTranslation()
	const router = useRouter()
	const query = router.query
	const tab = (query.tab as string) || 'flandersfood'
	const hasDateFilter =
		!!query['multiRange.date_timestamp'] ||
		!!query['numericMenu[date_timestamp]']

	const firstDayOfWeek = startOfWeek(today)
	const lastDayOfWeek = endOfWeek(today)
	const year1990 = new Date(1990, 0, 0, 0, 0, 0, 0)
	const firstDayOfMonth = startOfMonth(today)
	const lastDayOfMonth = endOfMonth(today)
	const firstDayOfNextMonth = startOfMonth(addMonths(today, 1))
	const lastDayOfNextMonth = endOfMonth(addMonths(today, 1))
	const fm = (date: Date) => getUnixTime(date)
	let index_postfix = '_asc'
	const [forceDateFilter, setForceDateFilter] = useState(false)

	if (
		hasDateFilter &&
		(query['multiRange.date_timestamp'] === `${fm(year1990)}:${fm(today)}` ||
			query['numericMenu[date_timestamp]'] === `${fm(year1990)}:${fm(today)}`)
	) {
		index_postfix = null
	}

	return (
		<Block type="fullcalendar" title={title} className={styles.fullcalendar()}>
			<Container>
				{title && (
					<Title.Marked
						as="h1"
						className={styles.fullcalendar.title()}
						mod="large"
					>
						{title}
					</Title.Marked>
				)}
				<Overview
					types={tab === 'all' ? ['event', 'ff_event'] : ['ff_event']}
					index_postfix={index_postfix}
					initial={_initial?.[tab]}
					future_dates={!hasDateFilter || forceDateFilter}
				>
					<OverviewTop>
						<OverviewTab isActive={tab !== 'all'} tab={undefined}>
							{t.calendar.tab_flandersfood}
						</OverviewTab>
						<OverviewTab isActive={tab === 'all'} tab={'all'}>
							{t.calendar.tab_all}
						</OverviewTab>
					</OverviewTop>
					<OverviewContent>
						<OverviewFilters>
							<Autocomplete
								placeholder={t.overview.searchbox.placeholder}
								detachedMediaQuery="none"
								openOnFocus
							/>
							<OverviewSearchLabel>{t.calendar.branding}</OverviewSearchLabel>
							<RefinementList attribute="event_branding" limit={30} />
							<OverviewSearchLabel>{t.calendar.regions}</OverviewSearchLabel>
							<HierarchicalMenu
								attributes={['event_region.lvl0', 'event_region.lvl1']}
							/>
							<OverviewSearchLabel>{t.calendar.dates}</OverviewSearchLabel>
							<NumericMenu
								attribute="date_timestamp"
								items={[
									{
										label: t.calendar.this_week,
										start: fm(firstDayOfWeek),
										end: fm(lastDayOfWeek)
									},
									{
										label: t.calendar.this_month,
										start: fm(firstDayOfMonth),
										end: fm(lastDayOfMonth)
									},
									{
										label: t.calendar.next_month,
										start: fm(firstDayOfNextMonth),
										end: fm(lastDayOfNextMonth)
									},
									{
										label: t.calendar.finished,
										start: fm(year1990),
										end: fm(today)
									}
								]}
								all={t.calendar.all}
								resetState={(s: IndexUiState) => {
									const {numericMenu, ...rest} = s
									setForceDateFilter(true)
									setTimeout(() => {
										setForceDateFilter(false)
									}, 300)
									return rest
								}}
							/>
							<OverviewSearchLabel>{t.calendar.price}</OverviewSearchLabel>
							<RefinementList attribute="event_price" />
							<OverviewSearchLabel>
								{t.overview.filters.tags}
							</OverviewSearchLabel>
							<RefinementList attribute="tags" limit={30} />
						</OverviewFilters>
						<OverviewResults label={t.events.resultsLabel} />
					</OverviewContent>
					<OverviewBottom />
				</Overview>
			</Container>
		</Block>
	)
}
