import {Block} from 'blocks/Block'
import {Image} from 'layout/Image'
import {Trusted} from 'layout/Trusted'
import React from 'react'
import {DPImageblockBlock} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import css from './Imageblock.module.scss'

const styles = fromModule(css)

export const Imageblock: React.FC<DPImageblockBlock> = ({
	image,
	id,
	caption
}) => {
	if (!image?.src) return null

	return (
		<Block type="imageblock" id={'img' + id} className={styles.imageblock()}>
			<figure>
				<Image
					{...image}
					layout={image.width < 1200 ? 'intrinsic' : 'responsive'}
					mod={image.width < 1200 ? 'centered' : undefined}
					sizes="(max-width: 1200px) 100vw, 1200px"
				/>
				{caption && (
					<figcaption className={styles.imageblock.caption()}>
						<Trusted>{caption}</Trusted>
					</figcaption>
				)}
			</figure>
		</Block>
	)
}
