import {Block} from 'blocks/Block'
import React from 'react'
import {DPFormBlock} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import css from './Form.module.scss'
import {FormIframe} from './FormIframe'

const styles = fromModule(css)

export const Form: React.FC<DPFormBlock> = ({formassembly_id, html_id}) => {
	return (
		<Block type="form" id={html_id || 'form'} className={styles.form()}>
			<FormIframe formassembly_id={formassembly_id} />
		</Block>
	)
}
