import React, {useEffect, useState} from 'react'

import {DPSidebarSfContactBlock} from 'types/DPTypes'
import {SidebarContact} from '../contact/SidebarContact'
import {SidebarCta} from '../cta/SidebarCta'
import {deobfuscate} from 'util/obfuscate'

export const SidebarSFContact: React.FC<DPSidebarSfContactBlock> = (data) => {
	const [contactEmail, setContactEmail] = useState(null)

	useEffect(() => {
		if (data.contact) setContactEmail(deobfuscate(data.contact?.email))
	}, [data])

	if (!data.contact) return null

	if (data.cta_label) {
		return (
			contactEmail && (
				<SidebarCta
					id={data.id}
					title={data.title}
					link={{
						title: data.cta_label,
						url: `mailto:${contactEmail}`
					}}
				/>
			)
		)
	}

	return <SidebarContact title={data.title} contact={[data.contact]} />
}
