import {Block} from 'blocks/Block'
import {ContactCard} from 'layout/cards/ContactCard'
import {Title} from 'layout/Title'
import React from 'react'
import {DPSfBoardBlock} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import css from './SFStrategicCommittee.module.scss'

const styles = fromModule(css)

export const SFStrategicCommittee: React.FC<DPSfBoardBlock> = ({
	title,
	contacts
}) => {
	if (contacts?.length === 0) return null

	return (
		<Block type="sf_board" id="sf-contacts">
			{title && (
				<Title.Marked className={styles.sfstrategiccommittee.title()}>
					{title}
				</Title.Marked>
			)}
			<div className={styles.sfstrategiccommittee.items()}>
				{contacts.map((contact, i) => (
					<div key={i} className={styles.sfstrategiccommittee.items.item()}>
						<ContactCard
							title={contact.name}
							function={contact.sf_functie}
							linkedin={contact.sf_linkedin}
							account={contact.sf_account}
							context_function={contact.context_function}
							context_function_en={contact.context_function_en}
						/>
					</div>
				))}
			</div>
		</Block>
	)
}
