import {
	Overview,
	OverviewBottom,
	OverviewContent,
	OverviewFilters,
	OverviewResults,
	OverviewSearchLabel,
	OverviewTab,
	OverviewTop
} from 'layout/overview/Overview'

import {Autocomplete} from 'util/autocomplete'
import {Block} from 'blocks/Block'
import {Container} from 'layout/Container'
import {DPFullarticlesBlock} from 'types/DPTypes'
import React from 'react'
import {RefinementList} from 'react-instantsearch-hooks-web'
import {Title} from 'layout/Title'
import css from './FullArticles.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useRouter} from 'next/dist/client/router'
import {useTranslation} from 'locale/translate'

const styles = fromModule(css)

export const FullArticles: React.FC<
	DPFullarticlesBlock & {
		_initial: Record<string, any>
	}
> = ({title, tabs, _initial}) => {
	const t = useTranslation()
	const router = useRouter()
	const {slug, tab} = router.query
	const activeTab = tabs.find((t) => t.key === tab) || tabs[0]

	return (
		<Block type="fullarticles" title={title} className={styles.fullarticles()}>
			<Container>
				{title && (
					<Title.Marked
						as="h1"
						className={styles.fullarticles.title()}
						mod="large"
					>
						{title}
					</Title.Marked>
				)}
				<Overview
					types={activeTab.types.map((type) => type.key)}
					index_postfix="_articles"
					initial={_initial && _initial[activeTab.key]}
				>
					<OverviewTop notabs={tabs.length <= 1}>
						{tabs.length > 1 &&
							tabs.map((tab) => (
								<OverviewTab
									isActive={activeTab === tab}
									key={tab.key}
									tab={tab.key || undefined}
								>
									{tab.label}
								</OverviewTab>
							))}
					</OverviewTop>
					<OverviewContent>
						<OverviewFilters>
							<Autocomplete
								placeholder={t.overview.searchbox.placeholder}
								detachedMediaQuery="none"
								openOnFocus
							/>
							<OverviewSearchLabel>
								{t.overview.filters.tags}
							</OverviewSearchLabel>
							<RefinementList attribute="tags" limit={30} />
						</OverviewFilters>
						<OverviewResults />
					</OverviewContent>
					<OverviewBottom />
				</Overview>
			</Container>
		</Block>
	)
}
