import {
	Overview,
	OverviewContent,
	OverviewFilters,
	OverviewResults,
	OverviewTop
} from 'layout/overview/Overview'

import {Autocomplete} from 'util/autocomplete'
import {Block} from 'blocks/Block'
import {Container} from 'layout/Container'
import React from 'react'
import {RefinementList} from 'react-instantsearch-hooks-web'
import {Title} from 'layout/Title'
import css from './Search.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'locale/translate'

const styles = fromModule(css)

export const Search: React.FC<{title?: string}> = ({title}) => {
	const t = useTranslation()

	return (
		<Block type="search" title={title} className={styles.search()}>
			<Container>
				{title && (
					<Title.Marked as="h1" className={styles.search.title()} mod="large">
						{title}
					</Title.Marked>
				)}
				<Overview default_empty initial={null}>
					<OverviewTop notabs={true} />
					<OverviewContent>
						<OverviewFilters>
							<Autocomplete
								placeholder={t.overview.searchbox.placeholder}
								detachedMediaQuery="none"
								openOnFocus
							/>
							<RefinementList attribute="tags" limit={30} />
						</OverviewFilters>
						<OverviewResults />
					</OverviewContent>
				</Overview>
			</Container>
		</Block>
	)
}
