import {DPNumbersBlock, DPNumbersItemBlock} from 'types/DPTypes'
import React, {useState} from 'react'

import AnimatedNumber from 'animated-number-react'
import {Block} from 'blocks/Block'
import {Button} from 'layout/Button'
import {Container} from 'layout/Container'
import ReactVisibilitySensor from 'react-visibility-sensor'
import {Title} from 'layout/Title'
import {Trusted} from 'layout/Trusted'
import css from './Numbers.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Numbers: React.FC<DPNumbersBlock> = ({...props}) => {
	return (
		<Block type="numbers" title={props.title} className={styles.numbers()}>
			<Container>
				<div className={styles.numbers.row()}>
					<NumbersBlock {...props} />
					<NumbersItems numbers={props.numbers} />
				</div>
			</Container>
		</Block>
	)
}

const NumbersBlock: React.FC<DPNumbersBlock> = ({title, description, link}) => {
	return (
		<div className={styles.block()}>
			<Title.H4 className={styles.block.title()} mod="inherit">
				{title}
			</Title.H4>
			<div className={styles.block.description()}>
				<Trusted>{description}</Trusted>
			</div>
			{link?.url && (
				<div className={styles.block.button()}>
					<Button to={link.url} mod="fullwidth">
						{link.title}
					</Button>
				</div>
			)}
		</div>
	)
}

const NumbersItems: React.FC<{numbers: DPNumbersItemBlock[]}> = ({numbers}) => {
	const [visible, setVisible] = useState<boolean>(false)
	if (!numbers || numbers.length === 0) return null
	const formatValue = (value) => Math.round(value)

	return (
		<ReactVisibilitySensor
			partialVisibility
			active={!visible}
			onChange={(isVisible) => {
				if (visible) return
				setVisible(isVisible)
			}}
		>
			<div className={styles.items()}>
				{numbers.map((number, i) => (
					<div key={i} className={styles.items.item()}>
						<p className={styles.items.item.number()}>
							<AnimatedNumber
								value={visible ? number.number : 0}
								formatValue={formatValue}
							/>
						</p>
						<p className={styles.items.item.title()}>{number.title}</p>
					</div>
				))}
			</div>
		</ReactVisibilitySensor>
	)
}
