import {Button} from './Button'
import React from 'react'
import css from './Cookieconsent.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useCookiebot} from 'util/cookiebot'
import {useTranslation} from 'locale/translate'

const styles = fromModule(css)

export type CookieType = {type: 'preferences' | 'statistics' | 'marketing'}

export const CookieconsentOptin: React.FC<CookieType> = ({type, children}) => {
	const cookiebot = useCookiebot()
	if (!cookiebot?.hasConsented(type)) return null

	return (
		<div
			data-cookieconsent={type}
			className={styles.optin.with(`cookieconsent-optin-${type}`)()}
		>
			{children}
		</div>
	)
}

export const CookieconsentOptout: React.FC<CookieType> = ({type}) => {
	const cookiebot = useCookiebot()
	const t = useTranslation().cookies

	return (
		<div className={styles.optout.with(`cookieconsent-optout-${type}`)()}>
			<div className={styles.optout.block()}>
				<p>{t.optout.text(type)}</p>
				<Button
					onClick={() => cookiebot?.renew()}
					className={styles.optout.block.button()}
					mod={['outline', 'inherit']}
				>
					{t.optout.button}
				</Button>
			</div>
		</div>
	)
}
