import {DPBannerTerm, DPImage} from 'types/DPTypes'

import {ConceptsLogo} from '../blocks/fullwidth/concepts/Concepts'
import {Container} from './Container'
import Image from 'next/image'
import {LinkStyled} from './LinkStyled'
import React from 'react'
import {Title} from './Title'
import css from './Hero.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Hero: React.FC<{className?: string}> = ({className, children}) => {
	return (
		<div className={styles.hero.with(className)()}>
			<Container>{children}</Container>
		</div>
	)
}

export const HeroBack: React.FC<{
	url: string
}> = ({url, children}) => {
	return (
		<div className={styles.back()}>
			<LinkStyled to={url} iconbefore="arrow_left">
				{children}
			</LinkStyled>
		</div>
	)
}

export const HeroTitle: React.FC<{
	className?: string
	mod?: 'concept'
	logo?: DPImage
}> = ({className, mod, children, logo}) => {
	return (
		<div className={styles.title()}>
			<div className={styles.title.row.mod(mod)()}>
				<Title.H2 as="h1" className={styles.title.text.with(className)()}>
					{children}
				</Title.H2>
				{logo?.src && (
					<div className={styles.title.logo.mod(mod)()}>
						{mod === 'concept' ? (
							<ConceptsLogo logo={logo} mod="hero" />
						) : (
							<Image priority {...logo} />
						)}
					</div>
				)}
			</div>
		</div>
	)
}

export const HeroSubTitle: React.FC<{className?: string}> = ({
	className,
	children
}) => {
	return <p className={styles.subtitle.with(className)()}>{children}</p>
}

export const HeroFinanciers: React.FC<{
	label: string
	banners: DPBannerTerm[]
}> = ({label, banners}) => {
	if (!banners || banners.length === 0) return null

	return (
		<div className={styles.financiers()}>
			{label && <p className={styles.financiers.label()}>{label}</p>}
			{banners.map((banner, i) => {
				if (!banner || !banner.image?.src) return null

				return (
					<div key={i} className={styles.financiers.item()}>
						{banner.image?.src && (
							<Image
								priority
								{...banner.image}
								className={styles.financiers.item.image()}
							/>
						)}
					</div>
				)
			})}
		</div>
	)
}
