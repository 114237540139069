import React from 'react'
import { DPSfPartnersBlock } from 'types/DPTypes'
import { Logos } from '../logos/Logos'

export const SFPartners: React.FC<DPSfPartnersBlock> = (data) => {

	return <Logos
		id={data.id}
		title={data.title}
		companies={data.partners}
		primary_companies={[]}
		text={null}
	/>
}
