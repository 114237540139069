import {DPImage} from 'types/DPTypes'
import {GalleryCarousel} from './GalleryCarousel'
import {Icon} from 'layout/Icon'
import Modal from 'react-responsive-modal'
import React from 'react'
import css from './GalleryModal.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const GalleryModal: React.FC<{
	open: boolean
	onClose: () => void
	selectedImage: DPImage | null
	images: DPImage[]
}> = ({open, onClose, selectedImage, images}) => {
	if (!selectedImage?.src) return null
	return (
		<Modal
			classNames={{
				root: styles.popup(),
				overlay: styles.popup.overlay(),
				modal: styles.popup.modal(),
				closeButton: styles.popup.closebutton()
			}}
			open={open}
			onClose={onClose}
			center
			closeIcon={<Icon icon="close_gallery" />}
		>
			<>
				<a
					download
					href={selectedImage.src}
					rel="noopener noreferrer"
					className={styles.popup.download()}
				>
					<Icon icon="download" />
				</a>
				<GalleryCarousel images={images} selectedImage={selectedImage} />
			</>
		</Modal>
	)
}
