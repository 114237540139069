import {useEffect, useMemo, useReducer} from 'react'

interface Cookiebot {
	/** Forces the cookie consent dialog to show. */
	show(): void
	/** Forces the cookie consent dialog to hide. */
	hide(): void
	/** Shows the cookie consent dialog to the website user to renew or change the user's consent state. */
	renew(): void
	/** Withdraw my consent for this website. */
	withdraw(): void

	/** True if the user has accepted cookies. */
	readonly consented: boolean
	readonly consent: {
		/** True if current user has accepted necessary cookies. */
		readonly necessary: boolean
		/** True if current user has accepted preference cookies. */
		readonly preferences: boolean
		/** True if current user has accepted statistics cookies. */
		readonly statistics: boolean
		/** True if current user has accepted marketing cookies. */
		readonly marketing: boolean
	}
	/** True if the user has declined the use of cookies. */
	readonly declined: boolean
	/** True if the user has responded to the dialog with either 'accept' or 'decline'. */
	readonly hasResponse: boolean
	/** True if the user has enabled the web browser's 'Do not track' (DNT) setting. If DNT is enabled Cookiebot will not set the third party cookie CookieConsentBulkTicket used for bulk consent. The property is read only.*/
	readonly doNotTrack: boolean
	readonly regulations: {
		/** Allows you to determine if GDPR applies based on the user's geo-location. */
		readonly gdprApplies: boolean
		/** Allows you to determine if CCPA applies based on the user's geo-location. */
		readonly ccpaApplies: boolean
		/** Allows you to determine if LGPD applies based on the user's geo-location. */
		readonly lgpdApplies: boolean
	}
}

declare const Cookiebot: Cookiebot | undefined

const events = [
	'CookiebotOnLoad',
	'CookiebotOnConsentReady',
	'CookiebotOnAccept',
	'CookiebotOnDecline'
]

export function useCookiebot() {
	const [, redraw] = useReducer((x) => x + 1, 0)
	useEffect(() => {
		const cancels = []
		events.forEach((event) => {
			window.addEventListener(event, redraw)
			cancels.push(() => window.removeEventListener(event, redraw))
		})
		return () => cancels.forEach((f) => f())
	}, [])
	function getCookiebot(): Cookiebot | undefined {
		return typeof window !== 'undefined' && window['Cookiebot']
	}
	return useMemo(() => {
		return {
			getCookiebot,
			show() {
				getCookiebot()?.show()
			},
			hide() {
				getCookiebot()?.hide()
			},
			renew() {
				getCookiebot()?.renew()
			},
			withdraw() {
				getCookiebot()?.withdraw()
			},
			hasAnswered() {
				return Boolean(getCookiebot()?.consented)
			},
			hasConsented(target: keyof Cookiebot['consent']): boolean {
				return Boolean(getCookiebot()?.consent?.[target])
			},
			hasConsentedAll(): boolean {
				const cookiebot = getCookiebot()
				return (
					cookiebot &&
					cookiebot.consent?.necessary &&
					cookiebot.consent?.preferences &&
					cookiebot.consent?.statistics &&
					cookiebot.consent?.marketing
				)
			}
		}
	}, [])
}
