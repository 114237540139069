import {Paragraphs, splitContent} from 'blocks/Block'
import {Personcard} from 'layout/cards/PersonCard'
import {Container} from 'layout/Container'
import {Blocks} from 'layout/content/Blocks'
import {Content, ContentBlocks, ContentSidebar} from 'layout/content/Content'
import {HeroBack, HeroTitle} from 'layout/Hero'
import {useTranslation} from 'locale/translate'
import React, {useContext} from 'react'
import {DPContactNode} from 'types/DPTypes'
import {GeneralContext} from 'types/general'
import {fromModule} from 'util/styler/Styler'
import css from './Contact.module.scss'

const styles = fromModule(css)

export const Contact: React.FC<DPContactNode> = (contact) => {
	const {links} = useContext(GeneralContext)
	const t = useTranslation()
	const {contentBlocks, fullBlocks} = splitContent(contact.blocks)
	const hasContent = contentBlocks.length > 0

	return (
		<div className={styles.contact()}>
			<div className={styles.contact.hero()}>
				<div className={styles.contact.hero.top()}>
					<Container>
						<HeroBack url={links.contact}>{t.contact.back}</HeroBack>
						<HeroTitle className={styles.contact.hero.top.title()}>
							{contact.title}
						</HeroTitle>
					</Container>
				</div>
				<div className={styles.contact.hero.content()}>
					<Container>
						<div className={styles.contact.hero.content.row()}>
							<div className={styles.contact.hero.content.left()} />
							<div className={styles.contact.hero.content.right()}>
								<Personcard {...contact} url={null} />
							</div>
						</div>
					</Container>
				</div>
			</div>
			{hasContent && (
				<Content>
					<ContentBlocks>
						<Paragraphs blocks={contentBlocks} />
					</ContentBlocks>
					<ContentSidebar></ContentSidebar>
				</Content>
			)}
			<Blocks>
				<Paragraphs blocks={fullBlocks} />
			</Blocks>
		</div>
	)
}
