import {
	DPFullmembersBlock,
	DPLink,
	DPNode,
	DPTitleblockBlock
} from 'types/DPTypes'
import {Hero, HeroBack, HeroSubTitle, HeroTitle} from 'layout/Hero'
import React, {useContext} from 'react'

import {Container} from 'layout/Container'
import {FullMembersLinks} from 'blocks/fullwidth/fullmembers/FullMembers'
import {GeneralContext} from 'types/general'
import Image from 'next/image'
import {ProgramTitlerow} from 'nodes/Program'
import {Trusted} from 'layout/Trusted'
import css from './Titleblock.module.scss'
import {dpDateToJsDate} from 'util/dates'
import {fromModule} from 'util/styler/Styler'
import {getDarkColorcode} from 'pages/_app'
import {useMediaQuery} from 'util/mediaquery'

const styles = fromModule(css)

export function getParentFromNode(node: DPNode): DPLink {
	switch (node?.type) {
		case 'page':
			return node.parent
		case 'roadmap':
			return node.program
	}
	return null
}

function getSubtitle(node: DPNode): string {
	switch (node?.type) {
		case 'roadmap':
			const jsFromDate = dpDateToJsDate(node.date)
			const jsToDate = dpDateToJsDate(node.to_date)
			if (jsFromDate && jsToDate) {
				return `${jsFromDate.getFullYear()} - ${jsToDate.getFullYear()}`
			}
	}
	return null
}

export const Titleblock: React.FC<DPTitleblockBlock & {stickout?: boolean}> = ({
	title,
	subtitle,
	image,
	logo,
	description,
	stickout
}) => {
	const {node} = useContext(GeneralContext)
	const parent = getParentFromNode(node)
	const darkColor = getDarkColorcode(node) || '#4b711d'
	const minIpadLand = useMediaQuery('(min-width: 1024px)')
	const hasImage = image && image.src
	const members = ('blocks' in node ? node.blocks : []).find(
		(bl) => bl.type === 'fullmembers'
	) as DPFullmembersBlock

	return (
		<div className={styles.titleblock.mod({image: hasImage})()}>
			<Hero className={styles.titleblock.hero.mod({back: parent?.url})()}>
				{parent?.url && <HeroBack url={parent.url}>{parent.title}</HeroBack>}
				<div className={styles.titleblock.row()}>
					<div className={styles.titleblock.row.left()}>
						{subtitle && <HeroSubTitle>{subtitle}</HeroSubTitle>}
						{node?.type !== 'program' && (
							<HeroTitle logo={logo}>{title || node?.title}</HeroTitle>
						)}
						{node?.type === 'program' && (
							<ProgramTitlerow color={darkColor} title={title || node?.title} />
						)}
					</div>
					{minIpadLand && (hasImage || stickout) && (
						<div className={styles.titleblock.row.right()} />
					)}
				</div>
				{members && <FullMembersLinks {...members} />}
			</Hero>
			{(hasImage || description) && (
				<div className={styles.titleblock.content()}>
					<Container>
						<div className={styles.titleblock.row()}>
							{(minIpadLand || description) && (
								<div className={styles.titleblock.row.left()}>
									{description && (
										<div className={styles.titleblock.content.text()}>
											<Trusted>{description}</Trusted>
										</div>
									)}
								</div>
							)}
							<div className={styles.titleblock.row.right()}>
								{hasImage && (
									<Image
										{...image}
										className={styles.titleblock.content.image()}
										layout={image.width < 600 ? 'intrinsic' : 'responsive'}
										sizes="(max-width: 500px) 100vw, 600px"
										priority
									/>
								)}
							</div>
						</div>
					</Container>
				</div>
			)}
		</div>
	)
}
