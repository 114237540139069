import {DPImage, DPProduct, DPUrl, DPWysiwyg} from 'types/DPTypes'

import {CardTitle} from 'layout/cards/Card'
import Image from 'next/image'
import {Link} from 'util/link'
import {ProductCartbutton} from 'nodes/Product'
import React from 'react'
import {Trusted} from 'layout/Trusted'
import css from './ShopRow.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const ShopRow: React.FC<{
	product: DPProduct
	show_cart?: boolean
}> = ({product, show_cart = false}) => {
	return (
		<div className={styles.shoprow.mod({cart: show_cart})()}>
			<div className={styles.shoprow.left()}>
				<ShopRowImage url={product.url} image={product.image} />
			</div>
			<div className={styles.shoprow.right()}>
				<ShopRowContent
					url={product.url}
					title={product.title}
					description={product.description}
					className={styles.shoprow.right.content()}
				/>
				{show_cart && (
					<div className={styles.shoprow.right.cart()}>
						<ProductCartbutton product={product} />
						{product.selling_price && (
							<div className={styles.shoprow.right.cart.text()}>
								<p>{`Prijs: € ${product.selling_price
									.toFixed(2)
									.toString()
									.replace(/\./g, ',')}`}</p>
								<p
									style={{whiteSpace: 'pre-line'}}
								>{`excl. BTW en incl. verzending binnen EU`}</p>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

export const ShopRowImage: React.FC<{
	image: DPImage
	url?: DPUrl
}> = ({image, url}) => {
	const LinkTag = url ? Link : 'div'
	const linkProps = url ? {to: url} : {}

	return (
		<LinkTag {...linkProps} className={styles.image()}>
			{image?.src && <Image {...image} />}
		</LinkTag>
	)
}

export const ShopRowContent: React.FC<{
	className?: string
	url?: DPUrl
	title: string
	description?: DPWysiwyg
}> = ({className, url, title, description}) => {
	if (!title && !description) return null

	return (
		<div className={styles.content.with(className)()}>
			<CardTitle title={title} url={url} />
			{description && (
				<div className={styles.content.description()}>
					<Trusted>{description}</Trusted>
				</div>
			)}
		</div>
	)
}
