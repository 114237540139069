import {CookieconsentOptin, CookieconsentOptout} from './Cookieconsent'
import React, {useState} from 'react'

import ReactPlayer from 'react-player'
import css from './Video.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Video: React.FC<{video_url: string}> = ({video_url}) => {
	const [isPlaying, setPlaying] = useState(false)
	if (!video_url) return null

	return (
		<div className={styles.video()} onClick={() => setPlaying(!isPlaying)}>
			<CookieconsentOptin type="marketing">
				<ReactPlayer
					url={video_url}
					playing={isPlaying}
					width={'100%'}
					height={'100%'}
					controls={true}
					style={{
						position: 'absolute',
						left: 0,
						right: 0,
						zIndex: 1
					}}
					playIcon={<span className={styles.video.play()} />}
				/>
			</CookieconsentOptin>
			<CookieconsentOptout type="marketing" />
		</div>
	)
}
