import {DPColumnsBlock, DPImage, DPWysiwyg} from 'types/DPTypes'

import {Block} from 'blocks/Block'
import {Container} from 'layout/Container'
import {Image} from 'layout/Image'
import {Imageblock} from 'blocks/content/imageblock/Imageblock'
import {LinksItem} from 'blocks/content/linksblock/Linksblock'
import React from 'react'
import {Title} from 'layout/Title'
import {Video} from 'layout/Video'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Columns.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Columns: React.FC<DPColumnsBlock> = ({
	id,
	column_left,
	column_right
}) => {
	let background = false
	if (column_left?.type === 'text' && column_left.has_background)
		background = true
	if (column_right?.type === 'text' && column_right.has_background)
		background = true

	return (
		<Block
			type="columns"
			id={'columns' + id}
			className={styles.columns.mod({background})()}
		>
			<Container>
				<div className={styles.columns.left()}>{switchItem(column_left)}</div>
				<div className={styles.columns.right()}>{switchItem(column_right)}</div>
			</Container>
		</Block>
	)
}

function switchItem(item) {
	if (!item?.type) return null
	switch (item.type) {
		case 'text':
			return <ColumnsText {...item} />
		case 'imageblock':
			return <Imageblock {...item} />
		case 'videoblock':
			return <ColumnsVideo {...item} />
		case 'linksblock':
			return <ColumnsLinks {...item} />
		case 'quotes_item':
			return <ColumnsQuote {...item} />
	}
}

const ColumnsText: React.FC<{text: DPWysiwyg}> = ({text}) => {
	if (!text) return null

	return (
		<div className={styles.text()}>
			<Wysiwyg>{text}</Wysiwyg>
		</div>
	)
}

const ColumnsVideo: React.FC<{
	title?: string
	text?: DPWysiwyg
	video_url: string
}> = ({title, text, video_url}) => {
	if (!video_url) return null

	return (
		<div className={styles.links()}>
			{title && (
				<Title.Marked className={styles.video.title()}>{title}</Title.Marked>
			)}
			{text && <Wysiwyg className={styles.video.text()}>{text}</Wysiwyg>}
			<div className={styles.video.video()}>
				<Video video_url={video_url} />
			</div>
		</div>
	)
}

const ColumnsLinks: React.FC<{
	title?: string
	text?: DPWysiwyg
	items: Array<any>
}> = ({title, text, items}) => {
	if (!items || items.length === 0) return null

	return (
		<div className={styles.links()}>
			{title && (
				<Title.Marked className={styles.links.title()}>{title}</Title.Marked>
			)}
			{text && <Wysiwyg className={styles.links.text()}>{text}</Wysiwyg>}
			<div className={styles.links.items()}>
				{items.map((item) => (
					<div key={item.id} className={styles.links.items.item()}>
						<LinksItem {...item} />
					</div>
				))}
			</div>
		</div>
	)
}

const ColumnsQuote: React.FC<{
	description: DPWysiwyg
	author?: string
	image?: DPImage
}> = ({description, author, image}) => {
	if (!description) return null

	return (
		<div className={styles.quote()}>
			<Wysiwyg className={styles.quote.description()}>{description}</Wysiwyg>
			{(author || image?.src) && (
				<div className={styles.quote.author()}>
					{author && <p className={styles.quote.author.title()}>{author}</p>}
					<Image
						{...image}
						className={styles.quote.author.image()}
						sizes="150px"
					/>
				</div>
			)}
		</div>
	)
}
