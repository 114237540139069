import {Block} from 'blocks/Block'
import InnerHTML from 'dangerously-set-html-content'
import React from 'react'
import {DPIframeBlock} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import css from './Iframe.module.scss'

const styles = fromModule(css)

export const Iframe: React.FC<DPIframeBlock> = ({iframe}) => {
	if (!iframe) return null
	const aspectratio = !!iframe.match(
		/dailymotion|maps|powtoon|vimeo|wistia|youtube/
	)
	const strippedIframe = iframe.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, '')

	return (
		<Block type="iframe" id="iframe" className={styles.iframe()}>
			<div className={styles.iframe.wrapper.mod({aspectratio})()}>
				<InnerHTML html={strippedIframe} />
			</div>
		</Block>
	)
}
