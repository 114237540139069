import {Carousel, useCarousel} from '@codeurs/carousel'
import {DPQuoteTerm, DPQuotesBlock, DPQuotesItemBlock} from 'types/DPTypes'

import {Block} from 'blocks/Block'
import {CarouselType} from 'types/basictypes'
import {Container} from 'layout/Container'
import {Icon} from 'layout/Icon'
import Image from 'next/image'
import React from 'react'
import {Trusted} from 'layout/Trusted'
import css from './Quotes.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'locale/translate'

const styles = fromModule(css)

export const Quotes: React.FC<DPQuotesBlock> = ({id, items, quotes}) => {
	const carousel = useCarousel()
	if (!items?.length && !quotes?.length) return null

	return (
		<Block type="quotes" id={'quotes' + id} className={styles.quotes()}>
			<Container>
				<div className={styles.quotes.innercontainer()}>
					<QuotesNav carousel={carousel} />
					<div className={styles.quotes.items()}>
						<Carousel {...carousel}>
							{(quotes || []).map((quote, i) => (
								<div
									key={`${i}${quote.id}`}
									className={styles.quotes.items.item.is({
										active: carousel.isActive(i)
									})()}
								>
									<QuotesItem {...quote} />
								</div>
							))}
							{(items || []).map((item, i) => (
								<div
									key={`${i}${item.id}`}
									className={styles.quotes.items.item.is({
										active: carousel.isActive((quotes?.length || 0) + i)
									})()}
								>
									<QuotesItem {...item} />
								</div>
							))}
						</Carousel>
					</div>
				</div>
			</Container>
		</Block>
	)
}

const QuotesItem: React.FC<DPQuotesItemBlock | DPQuoteTerm> = ({
	description,
	author,
	image
}) => {
	if (!description) return null

	return (
		<div className={styles.item()}>
			<blockquote className={styles.item.description()}>
				<Trusted>{description}</Trusted>
			</blockquote>
			{(author || image?.src) && (
				<div className={styles.item.author()}>
					{author && <p className={styles.item.author.title()}>{author}</p>}
					{image?.src && (
						<div className={styles.item.author.image()}>
							<Image {...image} />
						</div>
					)}
				</div>
			)}
		</div>
	)
}

const QuotesNav: React.FC<{carousel: CarouselType}> = ({carousel}) => {
	const t = useTranslation()
	if (carousel?.total === 1) return null

	return (
		<div className={styles.nav()}>
			<button
				onClick={() => carousel.goPrevious()}
				disabled={!carousel.hasPrevious()}
				aria-label={t.carousel.gotoprev}
				className={styles.nav.prev()}
			>
				<Icon icon="arrow_left" />
			</button>
			<div className={styles.nav.pages()}>
				<span className={styles.nav.pages.current()}>
					{carousel.current + 1}
				</span>
				<span className={styles.nav.pages.divider()}>/</span>
				<span className={styles.nav.pages.total()}>{carousel.total}</span>
			</div>
			<button
				onClick={() => carousel.goNext()}
				disabled={!carousel.hasNext()}
				aria-label={t.carousel.gotonext}
				className={styles.nav.next()}
			>
				<Icon icon="arrow_right" />
			</button>
		</div>
	)
}
