import {Card, CardText, CardTitle} from './Card'
import {DPBrandingTerm, DPEvent} from 'types/DPTypes'

import {Mod} from 'types/basictypes'
import css from './Eventcard.module.scss'
import {dpDateToJsDate} from 'util/dates'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'locale/translate'

const styles = fromModule(css)

export const Eventcard: React.FC<{
	event: DPEvent
}> = ({event}) => {
	return (
		<Card url={event.url} className={styles.eventcard()}>
			<EventcardBranding branding={event.branding} />
			<EventcardDate {...event} />
			<div className={styles.eventcard.content()}>
				<EventcardContent {...event} />
			</div>
		</Card>
	)
}

export const EventcardBranding: React.FC<{
	branding: DPBrandingTerm
}> = ({branding}) => {
	const t = useTranslation().event
	if (!branding?.icon?.src) return null

	return (
		<div className={styles.branding()}>
			<img src={branding.icon.src} alt={t.branding.icon} />
		</div>
	)
}

export const EventcardDate: React.FC<{
	date: string
	to_date?: string
	mod?: Mod<'large'>
}> = ({date, mod}) => {
	const t = useTranslation()
	const jsDate = dpDateToJsDate(date)
	if (!jsDate) return null
	const day = jsDate.getDate()

	return (
		<div className={styles.date.mod(mod)()}>
			<div className={styles.date.day()}>{day <= 9 ? `0${day}` : day}</div>
			<div className={styles.date.month()}>
				{t.months[jsDate.getMonth()]} {jsDate.getFullYear()}
			</div>
			<div className={styles.date.line()} />
		</div>
	)
}

export const EventcardContent: React.FC<{
	title: string
	location: string
}> = ({title, location}) => {
	const text = [location].join(', ')

	return (
		<div className={styles.content()}>
			<CardTitle as="h4" title={title} />
			<CardText text={text} className={styles.content.text()} />
		</div>
	)
}
