import React, {useCallback, useEffect, useState} from 'react'

import ClassNames from 'embla-carousel-class-names'
import {DPImage} from 'types/DPTypes'
import {EmblaOptionsType} from 'embla-carousel'
import {Icon} from 'layout/Icon'
import Image from 'next/image'
import css from './GalleryCarousel.module.scss'
import {fromModule} from 'util/styler/Styler'
import useEmblaCarousel from 'embla-carousel-react'

const styles = fromModule(css)

type PropType = {
	images: DPImage[]
	options?: EmblaOptionsType
	selectedImage: DPImage | null
}

export const GalleryCarousel: React.FC<PropType> = ({
	images,
	selectedImage
}) => {
	const [selectedIndex, setSelectedIndex] = useState(0)
	const [emblaMainRef, emblaMainApi] = useEmblaCarousel(
		{
			startIndex: selectedImage ? images.indexOf(selectedImage) : 0
		},
		[ClassNames()]
	)
	const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel(
		{
			containScroll: 'keepSnaps',
			dragFree: true,
			startIndex: selectedImage ? images.indexOf(selectedImage) : 0
		},
		[ClassNames()]
	)

	const onThumbClick = useCallback(
		(index: number) => {
			if (!emblaMainApi || !emblaThumbsApi) return
			emblaMainApi.scrollTo(index)
		},
		[emblaMainApi, emblaThumbsApi]
	)

	const onSelect = useCallback(() => {
		if (!emblaMainApi || !emblaThumbsApi) return
		setSelectedIndex(emblaMainApi.selectedScrollSnap())
		emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap())
	}, [emblaMainApi, emblaThumbsApi, setSelectedIndex])

	const scrollPrev = useCallback(
		() => emblaMainApi && emblaMainApi.scrollPrev(),
		[emblaMainApi]
	)
	const scrollNext = useCallback(
		() => emblaMainApi && emblaMainApi.scrollNext(),
		[emblaMainApi]
	)

	useEffect(() => {
		if (!emblaMainApi) return
		onSelect()
		emblaMainApi.on('select', onSelect)
		emblaMainApi.on('reInit', onSelect)
	}, [emblaMainApi, onSelect])

	return (
		<>
			<div className={styles.embla()} ref={emblaMainRef}>
				<div className={styles.embla.slides()}>
					{images.map((image, index) => (
						<div className={styles.embla.slides.slide()} key={index}>
							<Image
								{...image}
								layout="fill"
								objectFit={image.height > image.width ? 'contain' : 'cover'}
								sizes="1280px"
							/>
						</div>
					))}
				</div>
			</div>
			<button
				onClick={scrollPrev}
				className={styles.prev()}
				disabled={!emblaMainApi?.canScrollPrev()}
			>
				<Icon icon="chevron_left_gallery" />
			</button>
			<button
				onClick={scrollNext}
				className={styles.next()}
				disabled={!emblaMainApi?.canScrollNext()}
			>
				<Icon icon="chevron_right_gallery" />
			</button>
			<Thumbnails
				emblaThumbsRef={emblaThumbsRef}
				selectedIndex={selectedIndex}
				onThumbClick={onThumbClick}
				images={images}
			/>
		</>
	)
}

const Thumbnails: React.FC<{
	emblaThumbsRef: any
	selectedIndex: number
	onThumbClick: (index: number) => void
	images: DPImage[]
}> = ({emblaThumbsRef, selectedIndex, onThumbClick, images}) => {
	return (
		<div className={styles.thumbnails()} ref={emblaThumbsRef}>
			<div className={styles.thumbnails.slides()}>
				{images.map((image, index) => (
					<button
						type="button"
						onClick={() => onThumbClick(index)}
						className={styles.thumbnails.slides.slide
							.mod({
								portrait: image.height > image.width
							})
							.is({selected: index === selectedIndex})()}
						key={index}
					>
						<Image {...image} layout="fill" objectFit="cover" sizes="200px" />
					</button>
				))}
			</div>
		</div>
	)
}
