import {LinkStyled} from 'layout/LinkStyled'
import React from 'react'
import {DPSfAccountTerm} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import {Card, CardLogo, CardTitle} from './Card'
import css from './Membercard.module.scss'

const styles = fromModule(css)

export const Membercard: React.FC<DPSfAccountTerm> = ({
	name,
	logo,
	sf_website
}) => {
	const website = getWebsite(sf_website)

	return (
		<Card className={styles.membercard()}>
			<CardLogo image={logo} url={website?.url} />
			<CardTitle title={name} url={website?.url} />
			{website?.url && (
				<LinkStyled
					to={website.url}
					target="_blank"
					className={styles.membercard.link()}
					mod="inherit"
				>
					{website.title}
				</LinkStyled>
			)}
		</Card>
	)
}

function getWebsite(url) {
	if (!url) return null

	const hasHttp = url.substring(0, 4) === 'http'
	const websiteUrl = !hasHttp ? 'http://' + url : url
	const websiteTitle = url.replace(/(^\w+:|^)\/\//, '')

	return {
		url: websiteUrl,
		title: websiteTitle
	}
}
