import { CardImage, CardText, CardTitle } from 'layout/cards/Card'
import React from 'react'
import { DPArticle } from 'types/DPTypes'
import { fromModule } from 'util/styler/Styler'
import css from './ArticleRow.module.scss'

const styles = fromModule(css)

export const ArticleRow: React.FC<{
	article: DPArticle
}> = ({article}) => {
	return (
		<div className={styles.articlerow()}>
			<div className={styles.articlerow.content()}>
				<CardTitle as="h3" title={article.title} url={article.url} />
				<CardText
					text={article.description}
					className={styles.articlerow.content.text()}
					mod="large"
				/>
			</div>
			{article.image && (
				<CardImage
					image={article.image}
					url={article?.url}
					className={styles.articlerow.image()}
				/>
			)}
		</div>
	)
}
