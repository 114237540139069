import React, {useContext} from 'react'

import {Articlecard} from 'layout/cards/Articlecard'
import {Block} from 'blocks/Block'
import {Container} from 'layout/Container'
import {DPFeaturedarticlesBlock} from 'types/DPTypes'
import {GeneralContext} from 'types/general'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import css from './FeaturedArticles.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const FeaturedArticles: React.FC<DPFeaturedarticlesBlock> = ({
	title,
	articles,
	link
}) => {
	const {links, node} = useContext(GeneralContext)
	const isHomepage = node?.url === links.home

	return (
		<Block
			type="featuredarticles"
			title={title}
			className={styles.featuredarticles()}
		>
			<Container>
				<div className={styles.featuredarticles.title()}>
					{title &&
						(isHomepage ? (
							<Title.H3 as="h2">{title}</Title.H3>
						) : (
							<Title.Marked>{title}</Title.Marked>
						))}
				</div>
				{articles?.length && (
					<div className={styles.featuredarticles.items()}>
						{articles.map((article, i) => (
							<div key={i} className={styles.featuredarticles.items.item()}>
								<Articlecard {...article} />
							</div>
						))}
					</div>
				)}
				{link?.url && (
					<LinkStyled
						to={link?.url}
						iconafter="arrow_right"
						className={styles.featuredarticles.link()}
					>
						{link?.title}
					</LinkStyled>
				)}
			</Container>
		</Block>
	)
}
