import {Block} from 'blocks/Block'
import InnerHTML from 'dangerously-set-html-content'
import {Container} from 'layout/Container'
import React, {useContext} from 'react'
import {DPCookiesBlock} from 'types/DPTypes'
import {GeneralContext} from 'types/general'
import {fromModule} from 'util/styler/Styler'
import css from './Cookies.module.scss'

const styles = fromModule(css)

export const Cookies: React.FC<DPCookiesBlock> = () => {
	const lang = useContext(GeneralContext).lang
	const langISO = lang === 'en' ? 'EN' : 'NL'

	return (
		<Block type="cookies" id="cookies" className={styles.cookies()}>
			<Container>
				<InnerHTML
					html={`<script id="CookieDeclaration" data-culture=${langISO} src="https://consent.cookiebot.com/75c90221-cfb9-4808-b5c9-74e62ab00a10/cd.js" type="text/javascript"></script>`}
				/>
			</Container>
		</Block>
	)
}
