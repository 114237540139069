import {Block} from 'blocks/Block'
import {DPImagetextBlock} from 'types/DPTypes'
import Image from 'next/image'
import React from 'react'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './ImageText.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const ImageText: React.FC<DPImagetextBlock> = ({
	image,
	id,
	image_right,
	image_small,
	description,
	has_background
}) => {
	const isPortrait = image?.height > image?.width
	const imageProps = isPortrait
		? {
				src: image?.src,
				alt: image?.alt,
				layout: 'fill' as const,
				objectFit: 'contain' as const
		  }
		: {
				...image,
				layout: 'responsive' as const
		  }

	return (
		<Block
			type="imagetext"
			id={'imagetext' + id}
			className={styles.imagetext
				.mod({right: image_right})
				.mod({small: image_small})
				.mod({background: has_background})()}
		>
			<div className={styles.imagetext.row()}>
				{image?.src && (
					<div className={styles.imagetext.image.mod({portrait: isPortrait})()}>
						<Image
							{...imageProps}
							sizes="(max-width: 767px) 100vw, (max-width: 1600px) 50vw, 800px"
						/>
					</div>
				)}
				{description && (
					<div className={styles.imagetext.content()}>
						<Wysiwyg className={styles.imagetext.content.text()}>
							{description}
						</Wysiwyg>
					</div>
				)}
			</div>
		</Block>
	)
}
