import {Carousel, useCarousel} from '@codeurs/carousel'
import React, {useContext, useState} from 'react'

import {Block} from 'blocks/Block'
import {Button} from 'layout/Button'
import {CarouselType} from 'types/basictypes'
import {Container} from 'layout/Container'
import {DPProjectsBlock} from 'types/DPTypes'
import {GeneralContext} from 'types/general'
import {Icon} from 'layout/Icon'
import {LinkStyled} from 'layout/LinkStyled'
import {Projectcard} from 'layout/projects/Projectcard'
import {Title} from 'layout/Title'
import css from './Projects.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useMediaQuery} from 'util/mediaquery'
import {useTranslation} from 'locale/translate'

const styles = fromModule(css)

export const Projects: React.FC<DPProjectsBlock> = ({
	title,
	link,
	projects: projectsArray
}) => {
	const {links, node} = useContext(GeneralContext)
	const t = useTranslation().project
	const isHomepage = node?.url === links.home

	const carousel = useCarousel()
	const isDesktop = useMediaQuery('(min-width: 1200px)')

	const [loadProjects, setLoadProjects] = useState<boolean>(false)
	if (projectsArray?.length === 0) return null

	const projectsFirst = projectsArray.slice(0, 3)
	const projectsRest = projectsArray.slice(3, projectsArray.length)
	const projectsLoaded =
		loadProjects && projectsArray.length > 3
			? projectsFirst.concat(projectsRest)
			: projectsFirst

	const projects = projectsLoaded.map((project) => (
		<div key={project.node_id} className={styles.projects.items.item()}>
			<Projectcard project={project} />
		</div>
	))

	return (
		<Block type="projects" title={title} className={styles.projects()}>
			<Container>
				<div className={styles.projects.top()}>
					<div className={styles.projects.top.title()}>
						{title &&
							(isHomepage ? (
								<Title.H3 as="h2">{title}</Title.H3>
							) : (
								<Title.Marked>{title}</Title.Marked>
							))}
					</div>
					{!isDesktop && (
						<div className={styles.projects.top.carouselnav()}>
							<ProjectsCarouselnav carousel={carousel} />
						</div>
					)}
				</div>
				<div className={styles.projects.items()}>
					{isDesktop ? (
						projects
					) : (
						<ProjectsCarousel carousel={carousel}>{projects}</ProjectsCarousel>
					)}
				</div>
				<div className={styles.projects.loadmore()}>
					{!loadProjects && projectsArray.length > 3 && (
						<Button
							onClick={() => setLoadProjects(true)}
							mod={['outline', 'inherit']}
						>
							{t.load_more}
						</Button>
					)}
				</div>
				{(loadProjects || projectsArray.length <= 3) && (
					<LinkStyled
						to={link?.url || links.projects}
						iconafter="arrow_right"
						className={styles.projects.link()}
					>
						{link?.title || t.show_all}
					</LinkStyled>
				)}
			</Container>
		</Block>
	)
}

const ProjectsCarousel: React.FC<{carousel: CarouselType; children: any}> = ({
	carousel,
	children
}) => {
	return (
		<Carousel full {...carousel}>
			{children}
		</Carousel>
	)
}

const ProjectsCarouselnav: React.FC<{carousel: CarouselType}> = ({
	carousel
}) => {
	const t = useTranslation()
	if (carousel?.total <= 1) return null

	return (
		<div className={styles.carouselnav()}>
			<button
				onClick={() => carousel.goPrevious()}
				disabled={!carousel.hasPrevious()}
				aria-label={t.carousel.gotoprev}
				className={styles.carouselnav.prev()}
			>
				<Icon icon="arrow_left_large" />
			</button>
			<button
				onClick={() => carousel.goNext()}
				disabled={!carousel.hasNext()}
				aria-label={t.carousel.gotonext}
				className={styles.carouselnav.next()}
			>
				<Icon icon="arrow_right_large" />
			</button>
		</div>
	)
}
