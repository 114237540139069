import React from 'react'
import {DPSidebarBlock} from 'types/DPTypes'
import {styler} from 'util/styler/Styler'
import {SidebarBanner} from './sidebar/banner/SidebarBanner'
import {SidebarContact} from './sidebar/contact/SidebarContact'
import {SidebarCta} from './sidebar/cta/SidebarCta'
import {SidebarLinks} from './sidebar/links/SidebarLinks'
import {SidebarSFContact} from './sidebar/sf_contact/SidebarSFContact'

const styles = styler('sidebarblock')

const blocks = {
	sidebar_banner: SidebarBanner,
	sidebar_contact: SidebarContact,
	sidebar_cta: SidebarCta,
	sidebar_links: SidebarLinks,
	sidebar_sf_contact: SidebarSFContact
}

export const SidebarBlock: React.FC<{
	type: DPSidebarBlock['type'] | 'custom'
	className?: string
}> = ({type, children, className}) => {
	return <div className={styles.with(type).with(className)()}>{children}</div>
}

export const SidebarParagraph: React.FC<DPSidebarBlock> = (block) => {
	const Component: any = blocks[block.type]
	if (!Component)
		throw new Error(`No Block component defined for ${block.type}`)
	return <Component {...block} />
}
