import {Carousel, useCarousel} from '@codeurs/carousel'
import React, {useContext} from 'react'

import {Block} from 'blocks/Block'
import {CarouselType} from 'types/basictypes'
import {Container} from 'layout/Container'
import {DPCalendarBlock} from 'types/DPTypes'
import {Eventcard} from 'layout/cards/Eventcard'
import {GeneralContext} from 'types/general'
import {Icon} from 'layout/Icon'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import css from './Calendar.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useMediaQuery} from 'util/mediaquery'
import {useTranslation} from 'locale/translate'

const styles = fromModule(css)

export const Calendar: React.FC<DPCalendarBlock> = ({
	title,
	events: eventsArray
}) => {
	const {links} = useContext(GeneralContext)
	const t = useTranslation().events

	const carousel = useCarousel()
	const isDesktop = useMediaQuery('(min-width: 1200px)')

	if (eventsArray?.length === 0) return null

	const events = eventsArray.map((event) => (
		<div key={event.node_id} className={styles.calendar.items.item()}>
			<Eventcard event={event} />
		</div>
	))

	return (
		<Block type="calendar" title={title} className={styles.calendar()}>
			<Container>
				<div className={styles.calendar.top()}>
					<div className={styles.calendar.top.title()}>
						{title && <Title.Marked>{title}</Title.Marked>}
					</div>
					{!isDesktop && (
						<div className={styles.calendar.top.carouselnav()}>
							<CalendarCarouselnav carousel={carousel} />
						</div>
					)}
				</div>
				<div className={styles.calendar.items()}>
					{isDesktop ? (
						events
					) : (
						<CalendarCarousel carousel={carousel}>{events}</CalendarCarousel>
					)}
				</div>
				<LinkStyled
					to={links.calendar}
					iconafter="arrow_right"
					className={styles.calendar.link()}
				>
					{t.show_all}
				</LinkStyled>
			</Container>
		</Block>
	)
}

const CalendarCarousel: React.FC<{carousel: CarouselType; children: any}> = ({
	carousel,
	children
}) => {
	return (
		<Carousel full {...carousel}>
			{children}
		</Carousel>
	)
}

const CalendarCarouselnav: React.FC<{carousel: CarouselType}> = ({
	carousel
}) => {
	const t = useTranslation()
	if (carousel?.total <= 1) return null

	return (
		<div className={styles.carouselnav()}>
			<button
				onClick={() => carousel.goPrevious()}
				disabled={!carousel.hasPrevious()}
				aria-label={t.carousel.gotoprev}
				className={styles.carouselnav.prev()}
			>
				<Icon icon="arrow_left_large" />
			</button>
			<button
				onClick={() => carousel.goNext()}
				disabled={!carousel.hasNext()}
				aria-label={t.carousel.gotonext}
				className={styles.carouselnav.next()}
			>
				<Icon icon="arrow_right_large" />
			</button>
		</div>
	)
}
