import {Anchor, useCurrentAnchorID} from './content/anchor/Anchor'
import {DPBlock, DPTitleblockBlock} from 'types/DPTypes'
import React, {Fragment, createContext, useContext} from 'react'

import {Articles} from './fullwidth/articles/Articles'
import {Basket} from './fullwidth/basket/Basket'
import {Calendar} from './fullwidth/calendar/Calendar'
import {Columns} from './fullwidth/columns/Columns'
import {Concepts} from './fullwidth/concepts/Concepts'
import {Contacts} from './content/contacts/Contacts'
import {Container} from 'layout/Container'
import {Cookies} from './fullwidth/cookies/Cookies'
import {Details} from './fullwidth/details/Details'
import {Documents} from './content/documents/Documents'
import {FeaturedArticles} from './fullwidth/featuredarticles/FeaturedArticles'
import {Form} from './content/form/Form'
import {FullArticles} from './fullwidth/fullarticles/FullArticles'
import {FullCalendar} from './fullwidth/fullcalendar/FullCalendar'
import {FullContacts} from './fullwidth/fullcontacts/FullContacts'
import {FullMembers} from './fullwidth/fullmembers/FullMembers'
import {FullProjects} from './fullwidth/fullprojects/FullProjects'
import {Home} from './hero/home/Home'
import {Iframe} from './content/iframe/Iframe'
import {ImageText} from './content/imagetext/ImageText'
import {Imageblock} from './content/imageblock/Imageblock'
import {Linksblock} from 'blocks/content/linksblock/Linksblock'
import {Logos} from './content/logos/Logos'
import {Newsflash} from './fullwidth/newsflash/Newsflash'
import {Newsletter} from './fullwidth/newsletter/Newsletter'
import {Numbers} from './fullwidth/numbers/Numbers'
import {Overviewblock} from './content/overviewblock/Overviewblock'
import {Products} from './fullwidth/products/Products'
import {Programs} from './fullwidth/programs/Programs'
import {Projects} from './fullwidth/projects/Projects'
import {Quotes} from './content/quotes/Quotes'
import {Reusable} from './fullwidth/reusable/Reusable'
import {Roadmaps} from './content/roadmaps/Roadmaps'
import {SFBoard} from './content/sf_board/SFBoard'
import {SFContacts} from './content/sf_contacts/SFContacts'
import {SFPartners} from './content/sf_partners/SFPartners'
import {SFStrategicCommittee} from './content/sf_strategic_committee/SFStrategicCommittee'
import {Search} from './fullwidth/search/Search'
import {Shareblock} from './content/shareblock/Shareblock'
import {Shop} from './fullwidth/shop/Shop'
import {Steps} from './fullwidth/steps/Steps'
import {Text} from './content/text/Text'
import {Titleblock} from './hero/titleblock/Titleblock'
import {Videoblock} from 'blocks/content/videoblock/Videoblock'
import {slugify} from 'util/slugify'
import {styler} from 'util/styler/Styler'

const styles = styler('block')

export const BlockContext = createContext<{fullwidth: boolean; block: DPBlock}>(
	{
		fullwidth: true,
		block: null
	}
)

const fullWidthBlocks = {
	anchor: Anchor,
	home: Home,
	titleblock: Titleblock,
	featuredarticles: FeaturedArticles,
	fullarticles: FullArticles,
	fullcalendar: FullCalendar,
	fullcontacts: FullContacts,
	fullmembers: FullMembers,
	fullprojects: FullProjects,
	search: Search,
	articles: Articles,
	calendar: Calendar,
	columns: Columns,
	newsflash: Newsflash,
	newsletter: Newsletter,
	numbers: Numbers,
	programs: Programs,
	projects: Projects,
	shop: Shop,
	basket: Basket,
	products: Products,
	steps: Steps,
	concepts: Concepts,
	reusable: Reusable,
	cookies: Cookies,
	sf_contacts: SFContacts
}

const contentBlocks = {
	contacts: Contacts,
	documents: Documents,
	iframe: Iframe,
	imageblock: Imageblock,
	imagetext: ImageText,
	linksblock: Linksblock,
	logos: Logos,
	shareblock: Shareblock,
	text: Text,
	quotes: Quotes,
	videoblock: Videoblock,
	details: Details,
	roadmaps: Roadmaps,
	overviewblock: Overviewblock,
	form: Form,
	sf_partners: SFPartners,
	sf_board: SFBoard,
	sf_strategic_committee: SFStrategicCommittee
}

export function splitContent(blocks: DPBlock[]): {
	titleBlock: DPTitleblockBlock
	contentBlocks: DPBlock[]
	fullBlocks: DPBlock[]
} {
	const titleIndex = new Set(['titleblock']).has(blocks[0]?.type) ? 1 : 0
	const blocksWithoutTitle = blocks.slice(titleIndex)
	const firstFullWidthIndex = blocksWithoutTitle.findIndex(
		(bl) => fullWidthBlocks[bl.type]
	)

	return {
		titleBlock: titleIndex ? (blocks[0] as DPTitleblockBlock) : null,
		contentBlocks:
			firstFullWidthIndex === -1
				? blocksWithoutTitle
				: blocksWithoutTitle.slice(0, firstFullWidthIndex),
		fullBlocks:
			firstFullWidthIndex === -1
				? []
				: blocksWithoutTitle.slice(firstFullWidthIndex)
	}
}

export const Block: React.FC<
	{
		type: DPBlock['type']
		className?: string
		style?: any
	} & ({title: string} | {id: string})
> = ({type, children, style, className, ...rest}) => {
	const {fullwidth} = useContext(BlockContext)
	const anchorBlockID = useCurrentAnchorID()
	const ContainerTag = fullwidth && contentBlocks[type] ? Container : Fragment

	let id = undefined
	if ('title' in rest) id = slugify(rest.title)
	if ('id' in rest) id = rest.id
	if (anchorBlockID) id = anchorBlockID

	return (
		<div id={id} className={styles.with(type).with(className)()} style={style}>
			<ContainerTag>{children}</ContainerTag>
		</div>
	)
}

export const Paragraphs: React.FC<{
	blocks: DPBlock[]
}> = ({blocks}) => {
	const context = useContext(BlockContext)

	return (
		<>
			{blocks.map((block) => (
				<BlockContext.Provider
					value={{
						fullwidth: context.fullwidth,
						block: block
					}}
					key={block.id}
				>
					<Paragraph {...block} />
				</BlockContext.Provider>
			))}
		</>
	)
}

const Paragraph: React.FC<DPBlock> = (block) => {
	const Component = fullWidthBlocks[block.type] || contentBlocks[block.type]
	if (!Component)
		throw new Error(`No Block component defined for ${block.type}`)
	return <Component {...block} />
}
