import {Block} from 'blocks/Block'
import {Container} from 'layout/Container'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {useTranslation} from 'locale/translate'
import {switchProgramIcon} from 'nodes/Program'
import React from 'react'
import {DPProgram, DPProgramsBlock, DPRoadmap} from 'types/DPTypes'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import css from './Programs.module.scss'

const styles = fromModule(css)

export const Programs: React.FC<DPProgramsBlock> = ({programs, title}) => {
	if (programs?.length === 0) return null

	return (
		<Block type="programs" title={title} className={styles.programs()}>
			<Container>
				{title && (
					<Title.H3 as="h2" className={styles.programs.title()}>
						{title}
					</Title.H3>
				)}
				<div className={styles.programs.items()}>
					{programs.map((item, i) => (
						<div key={i} className={styles.programs.items.item()}>
							<ProgramsCard {...item} />
						</div>
					))}
				</div>
			</Container>
		</Block>
	)
}

const ProgramsCard: React.FC<DPProgram & {roadmaps: DPRoadmap[]}> = ({
	title,
	url,
	color,
	roadmaps
}) => {
	const t = useTranslation()

	return (
		<div className={styles.card()}>
			<div className={styles.card.icon()} style={{color: color?.colorcode}}>
				{switchProgramIcon(color?.colorcode)}
			</div>
			<Title.H4 className={styles.card.title()}>
				<Link to={url}>{title}</Link>
			</Title.H4>
			<div className={styles.card.projects()}>
				{roadmaps.map((roadmap) => (
					<div key={roadmap.url} className={styles.card.projects.item()}>
						<Link to={roadmap.url} className={styles.card.projects.item.link()}>
							{roadmap.title}
						</Link>
					</div>
				))}
			</div>
			<div className={styles.card.link()}>
				<LinkStyled to={url} iconafter="arrow_right">
					{t.programs.discover}
				</LinkStyled>
			</div>
		</div>
	)
}
