import {AutocompleteOptions, autocomplete} from '@algolia/autocomplete-js'
import {Fragment, createElement, useEffect, useRef, useState} from 'react'
import {usePagination, useSearchBox} from 'react-instantsearch-hooks-web'

import {BaseItem} from '@algolia/autocomplete-core'
import algoliasearch from 'algoliasearch'
import {createLocalStorageRecentSearchesPlugin} from '@algolia/autocomplete-plugin-recent-searches'
import {createQuerySuggestionsPlugin} from '@algolia/autocomplete-plugin-query-suggestions'
import {render} from 'react-dom'

type AutocompleteProps = Partial<AutocompleteOptions<BaseItem>> & {
	className?: string
}

type SetInstantSearchUiStateOptions = {
	query: string
}

const searchClient = algoliasearch(
	process.env.NEXT_PUBLIC_ALGOLIA_ID || 'K7SK5EXOG3',
	process.env.NEXT_PUBLIC_ALGOLIA_PUBLIC_KEY ||
		'f92e590a2f2ed546a4ed5058da5c3afc'
)

const searchPlugins = () => {
	const localSearches = createLocalStorageRecentSearchesPlugin({
		key: 'autocomplete',
		limit: 3
	})

	if (!process.env.ALGOLIA_SUGGESTIONS_INDEX) return [localSearches]

	const suggestionSearches = createQuerySuggestionsPlugin({
		searchClient,
		indexName: process.env.ALGOLIA_SUGGESTIONS_INDEX,
		getSearchParams({state}) {
			if (!state.query)
				return {
					hitsPerPage: 0
				}
			return localSearches.data.getAlgoliaSearchParams({
				hitsPerPage: 5
			})
		}
	})
	return [localSearches, suggestionSearches]
}

export function Autocomplete({
	className,
	...autocompleteProps
}: AutocompleteProps) {
	const autocompleteContainer = useRef<HTMLDivElement>(null)

	const {query, refine: setQuery} = useSearchBox()
	const {refine: setPage} = usePagination()

	const [instantSearchUiState, setInstantSearchUiState] =
		useState<SetInstantSearchUiStateOptions>({query})

	useEffect(() => {
		setQuery(instantSearchUiState.query)
		setPage(0)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [instantSearchUiState])

	useEffect(() => {
		if (!autocompleteContainer.current) {
			return
		}

		const autocompleteInstance = autocomplete({
			...autocompleteProps,
			container: autocompleteContainer.current,
			initialState: {query},
			onReset() {
				setInstantSearchUiState({query: ''})
			},
			onSubmit({state}) {
				setInstantSearchUiState({query: state.query})
			},
			onStateChange({prevState, state}) {
				if (prevState.query !== state.query) {
					setInstantSearchUiState({
						query: state.query
					})
				}
			},
			plugins: searchPlugins(),
			renderer: {createElement, Fragment, render}
		})

		return () => autocompleteInstance.destroy()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <div className={className} ref={autocompleteContainer} />
}
