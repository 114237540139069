import {DPGalleryNode, DPImage} from 'types/DPTypes'
import React, {useContext, useState} from 'react'

import {Copyright} from 'layout/app/Copyright'
import {GalleryModal} from 'layout/gallery/GalleryModal'
import {GeneralContext} from 'types/general'
import {Link} from 'util/link'
import Logo from 'assets/logo.svg'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Gallery.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'locale/translate'

const styles = fromModule(css)

export const Gallery: React.FC<Omit<DPGalleryNode, 'type'>> = ({
	title,
	gallery_description,
	gallery_image,
	footer
}) => {
	const [open, setOpen] = useState<boolean>(false)
	const {footer: globalFooter} = useContext(GeneralContext)
	const onOpenModal = () => setOpen(true)
	const onCloseModal = () => setOpen(false)

	const [selectedImage, setSelectedImage] = useState<DPImage | null>(null)
	return (
		<div className={styles.gallery()}>
			<GalleryModal
				images={gallery_image}
				open={open}
				onClose={onCloseModal}
				selectedImage={selectedImage}
			/>
			<div className={styles.gallery.items.is({blurred: open})()}>
				<div className={styles.gallery.items.item.mod('intro')()}>
					<Intro title={title} gallery_description={gallery_description} />
				</div>
				{gallery_image.map((image, i) => {
					if (!image?.src) return null
					const src = image.src.replace(
						'/files/',
						`/files/styles/next_640/public/`
					)
					return (
						<button
							onClick={() => {
								setSelectedImage(image)
								onOpenModal()
							}}
							className={styles.gallery.items.item()}
							key={i}
						>
							{/* <Image {...image} /> */}
							<img {...image} src={src} />
						</button>
					)
				})}
			</div>
			<Wysiwyg className={styles.gallery.footer()}>{footer}</Wysiwyg>
			<Copyright
				text={globalFooter.copyright_description}
				links={globalFooter.footer_links}
			/>
		</div>
	)
}

const Intro: React.FC<{title: string; gallery_description: string}> = ({
	title,
	gallery_description
}) => {
	const t = useTranslation()
	const {links} = useContext(GeneralContext)
	return (
		<div className={styles.intro()}>
			<Link
				to={links.home}
				title={t.project_name}
				className={styles.intro.logo()}
				aria-label={t.homepage.arialabel}
			>
				<Logo />
			</Link>
			<h1 className={styles.intro.title()}>{title}</h1>
			<Wysiwyg className={styles.intro.description()}>
				{gallery_description}
			</Wysiwyg>
		</div>
	)
}
