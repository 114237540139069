import { EventDetails } from 'nodes/Event'
import React, { useContext } from 'react'
import { DPDetailsBlock } from 'types/DPTypes'
import { GeneralContext } from 'types/general'

export const Details: React.FC<DPDetailsBlock> = () => {
	const {node} = useContext(GeneralContext)

	switch(node.type){
		case 'event':
			return <EventDetails {...node} />
	}

	return null
}
