import React from 'react'
import { fromModule } from 'util/styler/Styler'
import css from './Loader.module.scss'

const styles = fromModule(css)

export const Loader: React.FC = () => {

	return <div className={styles.loader()}>
		<div className={styles.loader.ring()}>
			<div />
			<div />
			<div />
			<div />
		</div>
	</div>
}